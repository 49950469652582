import React , { useContext }from 'react'
import { Button, Modal, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../context/loginContext';
import { requestForConfirmMail } from '../mailConfirmation/mailConfirmationService';


function MailConfirmation(props) {  
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  
  let {
    loginData: {
      accessToken,
    }, 
	dispatch
  } = useContext(LoginContext);  

  const token_lang = {
    language: i18n.language,
    accessToken
  }

  function goToChangeEmail(){
    dispatch({ type: 'IS_CONFIRM_MAIL_POPUP', data: false });
    navigate('/membership/personaldata', { state: { changeEmailIndex: "change_email" } })
  }

  function requestMailConfirm() {
    requestForConfirmMail(token_lang).then((response) => {
      if (response?.status == 200) {
        props.handleShowToast();
        props.close();
      }
    })
  }

  return (
    <Modal className="mail_confimation" size='lg' show={props.showPopup}
      onHide={() => props.close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("member.mail_conform.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>{t("member.mail_conform.body")}</strong>
        <p>
          {t("member.mail_conform.mail_name", { email_address: props.email })}
        </p>
        <p>
          {t("member.mail_conform.request_msg")}</p>
        <div className="mail_confimation_btns">
          <Button onClick={goToChangeEmail} className='mx-5' as={Col} variant="primary" type="submit">
            {t("member.mail_conform.change_email")}
          </Button>
          <Button onClick={requestMailConfirm} className='mx-5' as={Col} variant="primary" type="submit">
            {t("member.mail_conform.confirm_mail")}
          </Button>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger"
          onClick={() => { props.close() }}
        >
          {t('common.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MailConfirmation