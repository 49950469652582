import React from "react";
import payment_icons from "../../assets/images/payrexx_icons.png";
import Button from "react-bootstrap/Button";
import { Trans } from "react-i18next";

function PrePaymentDetails({
  prepaymentData,
  scrollRef,
  t,
  isChangePaymentMethod,
  changeMethod,
  onNext,
}) {

    const amountText = t("registration.prepayment.ptta")
    .replace(
      /\$\{amount\}/g,
      `<strong class="prepayment_highlight">${prepaymentData?.amount} ${prepaymentData?.currency}</strong>`
    )
    .replace(
      /\$\{externalReferenceId\}/g,
      `<strong class="prepayment_highlight">${prepaymentData?.externalReferenceId}</strong>`
    )
    .replace(
      /\$\{payment_skip\}/g,
      `<a href="#" onClick="document.getElementById('button_payment_next').click();return false;" class="prepayment_link">${t(
        "registration.prepayment.paymentSkip"
      )}</a>`
    );

    
  return (
    <div className="from_control questionair_container">
      <div className="questionair_header" ref={scrollRef}>
        <h3 className="questionair_heading">
          {t("registration.pendingPayment.paymentDetails")}
        </h3>
        <div className="payment_methods">
          <div className="prepayment_result">
            {prepaymentData && <p dangerouslySetInnerHTML={{ __html: amountText }}></p>}
          </div>
          <div className="prepayment_details">
            <h2>{t("registration.prepayment.bankTransfer")}</h2>
            <div className="bank_name">
              <p>GLS Bank </p>
            </div>
            <div className="bank_info">
              <p>
                <span>
                  <b>{t("registration.prepayment.accountOwner")}</b>
                </span>
                <span>Gleichklang Limited</span>
              </p>
              <p>
                <span>
                  <b>IBAN:</b>
                </span>
                <span>{prepaymentData?.prePaymentBankAccounts[0]?.iban}</span>
              </p>
              <p>
                <span>
                  <b>BIC:</b>
                </span>
                <span>{prepaymentData?.prePaymentBankAccounts[0]?.bic}</span>
              </p>
              <p>
                <span>
                  <b>{t("registration.prepayment.amount")}</b>
                </span>
                <span>{prepaymentData?.amount}</span>
              </p>
              <p>
                <span>
                  <b>{t("registration.prepayment.paymentReference")}</b>
                </span>
                <span>{prepaymentData?.externalReferenceId}</span>
              </p>
            </div>
          </div>
          <div className="onlinepayment_details">
            <h2>{t("registration.prepayment.payOnline")}</h2>
            <div className="bank_name">
              <p>{t("registration.prepayment.secureFast")}</p>
            </div>
            <p>{t("registration.prepayment.onlinepaymentDescription")}</p>
            <a
              className="btn btn-primary"
              href={
                "https://gleichklang.payrexx.com/pay?tid=f4ab5d56&invoice_amount=" +
                prepaymentData?.amount +
                "&custom_referenz=" +
                prepaymentData?.externalReferenceId
              }
              target="_blank"
            >
              {t("registration.prepayment.payNow")}
            </a>
            <img
              src={payment_icons}
              alt={t("registration.prepayment.payOnline")}
            />
          </div>
        </div>
      </div>
      {isChangePaymentMethod === false ? (
        <div className="questionair_header">
          <Button
            className="change_button"
            variant="primary"
            onClick={changeMethod}
          >
            {t("registration.payment.pay_via_direct_debit")}
          </Button>
        </div>
      ) : null}
      {isChangePaymentMethod === false ? (
        <div className="mt-3 step_one_next">
          <Button
            className="main_button"
            variant="primary"
            onClick={() => onNext()}
          >
            {t("registration.buttons.next")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default PrePaymentDetails;
