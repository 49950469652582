import React, { useEffect, useState, useContext, useRef } from 'react'
import GlkHeader from '../../components/Header/GlkHeader';
import membershipIcon from '../../assets/images/audio.png';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner';
import Button from 'react-bootstrap/Button';
import friendshipIcon from '../../assets/images/icon_category_friendship.png'
import partnershipIcon from '../../assets/images/icon_category_partnership.png'
import { LoginContext } from '../../context/loginContext';
import { getAllAudios, updateAudio, uploadAudio, getAudio } from './personalDetailsService.js';
import { AudioRecorder } from 'react-audio-voice-recorder';
import './audio.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faStop, faTimes, faVolumeHigh, faUserFriends, faHeart } from '@fortawesome/free-solid-svg-icons';
import UpdateAudioPopup from './updateAudioPopup.js';
import Player from '../../components/Audioplayer/Player';
import Alert from 'react-bootstrap/Alert';
import { ProgressBar } from 'react-bootstrap';

function Audio() {
  const [onFieldReset, setOnFieldReset] = useState(false);
  const { t, i18n } = useTranslation();
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  const data = {
    "image": membershipIcon,
    "menuName": t('member.audio.menuName'),
    "header": t('member.audio.header'),
    "description": t('member.audio.description'),
    "color": "#464565"
  }

  const [audioData, setAudioData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [type, setType] = useState(null)
  const [show, setShow] = useState(false)
	const [showRecordButton, setShowRecordButton] = useState(true);
  const inputFileSelect = useRef();
  const [playedAudioUrl, setPlayedAudioUrl] = useState(null)
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const [playedAudioId, setPlayedAudioId] = useState(null);
  const [errorMsg, setErrorMsg] = useState();
  const [progress, setProgress] = useState(0);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  
  useEffect(() => {
    if (!audioData && audioData === null) {
      getAudios()
    }
    setTimeout(() => {
      const eleAudio = document.getElementsByClassName('audio-recorder-mic')
      const eleAudioOption = document.getElementsByClassName('audio-recorder-options')
      if(eleAudioOption && eleAudioOption.length > 0) {
        for (let index = 0; index < eleAudioOption.length; index++) {
          const element = eleAudioOption[index];
          element.setAttribute('alt', "Audio "+element.getAttribute('title'))
        }
      }
      if(eleAudio && eleAudio[0]) {
        eleAudio[0].setAttribute('alt', 'Audio Start recording')
      }
    }, 2000);    
  }, [])

  const getAudios = () => {
    const data = {
      accessToken: accessToken,
      language: i18n.language
    }
    getAllAudios(data).then((response) => {
      if (response?.status === 200) {
        setAudioData(response?.data)
      }
    })
  }

const playAudio = (index) => {
  setPlayedAudioId(index)
  setProgress(0)
  const data = {
    accessToken: accessToken,
    language: i18n.language,
    audioId: audioData?.audios?.[index]?.id
  }
  getAudio(data, {
    onDownloadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      if (total) {
        const percentage = Math.round((loaded * 100) / total);
        setProgress(percentage); 
      }
    }
  })
    .then(response => {
      processAudio(new Response(response), audioData?.audios?.[index]?.name);
    })
    .catch(error => {
      console.error('Error fetching audio:', error);
    });
};

  const processAudio = async (response, audioName) => {

    const blob = await response.blob();
    if (response.ok) {
      const fileURL = URL.createObjectURL(blob);
      const data = {
        fileName: audioName,
        url: fileURL
      }
      setPlayedAudioUrl(data)
      setIsAudioPlay(true);
    }
  }

  const updateAudioData = (audio) => {
    const data = {
      accessToken: accessToken,
      language: i18n.language,
      body: audio
    }
    updateAudio(data).then((response) => {
      if (response?.status === 200) {
        let newData = { ...audioData }
        newData.audios[currentIndex] = audio
        setAudioData(newData)
      } else {
        setErrorMsg(response.data.message)
      }
    })
  }

  const deleteAudioFile = () => {
    const audio = { ...audioData?.audios?.[currentIndex], delete: true }
    const data = {
      accessToken: accessToken,
      language: i18n.language,
      body: audio
    }
    updateAudio(data).then((response) => {
      if (response?.status === 200) {
        let newData = { ...audioData }
        newData.audios.splice(currentIndex, 1)
        setAudioData(newData)
      }
    })
    setShow(false)
  }

  const uploadAudioFile = (fileData, index) => {
    setOnFieldReset(true);
    setIsAlertVisible(true);
    let formData = new FormData();
    if (fileData?.size > 2e+7) {
      setErrorMsg(t('member.audio.audiosize'));
      return;
    }
    formData.append('file', fileData, fileData.name);
    const data = {
      accessToken: accessToken,
      language: i18n.language,
      form: formData
    }
    uploadAudio(data).then((response) => {  
      if (response?.status === 200) {
        getAudios();
        setOnFieldReset(false);
        setIsAlertVisible(false);
      }
      else {
        setErrorMsg(response.data.message)
        setOnFieldReset(false);
      }
    });
  }

  const recordAudio = (blob) => {
    let fileName = `me-at-thevoice_${Date.now()}.mp3`
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    const uploadFile = new File([blob], fileName, { type: 'audio/mpeg' })
    uploadAudioFile(uploadFile)
		setShowRecordButton(true)
  }
	
	const mic = document.querySelector('.audio-recorder-mic');
  if (mic) {
		mic.onclick = function () {
			setShowRecordButton(false)
		};
  }

  const dismissError = () => {
    setErrorMsg(null);
  };  
  return (
    <div onClick={dismissError}>
			<GlkHeader/>
			<main className="main_wrapper personal_details_wrapper audio_wrapper">
      <Banner data={data} />
      <div className='questionaire_in_menubar'>
        <div className="status_myprofile">
          <h3>{t('member.audio.myaudiofiles')}</h3>
        </div>

        {audioData !== null ? audioData?.audios.map((audio, index) => (        
          <div key={`card_group_${index}`} className="content-filter">
            <div className="full-row">
              <div className="half audio_left">
                <Button
                  variant="outline-secondary" 
                  aria-label="Start Audio" 
                  onClick={() => {
                    setPlayedAudioUrl(null);
                    setIsAudioPlay(false);
                    playAudio(index)}}
                >
                  <FontAwesomeIcon icon={faVolumeHigh} />
                </Button>
                <h4>{audio.name.split('.mp3')[0]}</h4>
              </div>
              <div className="half audio_right">

                 {(!isAudioPlay && playedAudioId == index && progress !== 0) ? (
                <div className='w-50'>
                  <ProgressBar striped variant="success" now={progress} label={`${progress}%`} key={3} />
                </div>
              ) : (isAudioPlay  && playedAudioId == index) ?
                    <div className='upload_audio'>
											<Player
												audioUrl={playedAudioUrl.url}
											/>
                      <Button 
                        variant="outline-danger" 
                        onClick={() => {
                          setPlayedAudioUrl(null);
                          setIsAudioPlay(false);
                          setProgress(0)
                          }}>
                          X
                        </Button>
                    </div> 
                  :
                  <>
                  <span className="share_col">
                    {audio.partnershipShared || audio.friendshipShared === true ?
                    <strong>{t('member.audio.sharedwith')}:</strong> : null }
                    {audio.partnershipShared === true ? 
                    <span className="share_icon share_partnership"  title={`${t('member.menubar.partnership')} ${index}`} alt={`${t('member.menubar.partnership')} ${index}`}>
                      <FontAwesomeIcon icon={faHeart} /> {t('member.menubar.partnership')}
                    </span> : null}
                    {audio.friendshipShared === true ? 
                    <span className="share_icon share_friendship" title={`${t('member.menubar.friendship')} ${index}`} alt={`${t('member.menubar.friendship')} ${index}`}>
                      <FontAwesomeIcon icon={faUserFriends} /> {t('member.menubar.friendship')}
                    </span> : null}
                  </span>
                <span>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setCurrentIndex(index)
                      setType('rename')
                      setShow(true)
                    }}>{t('common.rename')}</Button>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      setCurrentIndex(index)
                      setType('share')
                      setShow(true)
                    }}>{t('common.share')}</Button>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      setCurrentIndex(index)
                      setType('delete')
                      setShow(true)
                    }}>{t('common.delete')}</Button>
                </span></>  }
              </div>
            </div>
          </div>
        )) : null}
        {errorMsg ? <Alert
          className='member_address'
          variant="danger"
          id='main_alert'
          onClose={() => setErrorMsg()}
          dismissible
        >
          <p>{errorMsg}</p>
        </Alert> : null}
        {isAlertVisible && (
          <div className='w-100 mt-2'>
          <Alert 
          severity="success" 
          className='member_address'
          variant="danger"
          id='main_alert'
          dismissible><p>{t('member.audio.audioAlert')}</p></Alert>
        </div>
        )}
        {audioData !== null && audioData?.audios.length < 10 ?
            <div className='record_audio'>
              <Button 
                variant='success' 
                className='uploadButton'
                onClick={() => { 
                  inputFileSelect.current.click();
                }}
                ><FontAwesomeIcon icon={faUpload} /> {t('member.audio.upload')}
              </Button>
						<div className='record_voice'>
							<div>
								<AudioRecorder 
									onRecordingComplete={recordAudio}
									showVisualizer={true} 
										audioTrackConstraints={{
											noiseSuppression: true,
											echoCancellation: true,
										}}
										downloadOnSavePress={false}
										downloadFileExtension="mp3" 
								/>
								{ showRecordButton ? <Button
								variant='success' 
								className='recordButton' 
								onClick={() => { document.querySelector('.audio-recorder-mic').click(); setShowRecordButton(false)}}
								>{t('member.audio.record')} 
								</Button> : null }
								{ !showRecordButton ? <div className="recordControls">	
									<Button
									className='stopandsaveButton' 
									onClick={() => document.querySelector('.audio-recorder-mic').click()}>
										<FontAwesomeIcon icon={faStop} /> {t('member.audio.stopandsave')}
									</Button>
									<Button
									className='abortButton' 
									aria-label={t('member.audio.abort')}
									onClick={() => {
										document.querySelectorAll('.audio-recorder-options')[1].click();
										setShowRecordButton(true)
										}
										}><FontAwesomeIcon icon={faTimes} /></Button>
								</div> : null }
							</div>							
						</div>
          </div>
          :
          <>
            {audioData !== null ? <Alert
              className='member_address'
              variant="danger"
              id='main_alert'
              dismissible
            >
              <p>{t('member.audio.maxaudio')}</p>
            </Alert> : null}
          </>
        }
        <UpdateAudioPopup
          show={show}
          setShow={setShow}
          currentIndex={currentIndex}
          audioData={audioData}
          updateAudioData={updateAudioData}
          deleteAudioFile={deleteAudioFile}
          type={type}
        />
        <div className='card_input'>
          <input id="file-upload"
            ref={inputFileSelect} 
            aria-label={t('member.avtars.fileUpload')} 
            name="audio-and-image-upload"
            type={onFieldReset ? "reset" : "file"}
            onChange={(e) => {
              e.preventDefault();
              uploadAudioFile(e.target.files[0]);
            }}
          />
        </div>
      </div>
		</main>
    </div>
  )
}

export default Audio;