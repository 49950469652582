import React, { useState } from 'react';
import './player.css';
import WavesurferPlayer from '@wavesurfer/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';

export default function Player(props) {

  const [wavesurfer, setWavesurfer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)	
  const [currentPlayTime, setCurrentPlayTime] = useState('0:00')
	const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

  const onReady = (ws) => {
    setWavesurfer(ws)
    setIsPlaying(false)
		if (ws){
			setCurrentPlayTime(formatTime(Math.floor(ws.getDuration())))
		}
  }

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause()
		if (wavesurfer){
			wavesurfer.on('timeupdate', function () {
				 setCurrentPlayTime(formatTime(Math.floor(wavesurfer.getCurrentTime())))
			})
		}
  }
	

  return (
    <div className="wave_surfer">
      <button onClick={onPlayPause} aria-label= {isPlaying ? 'Pause' : 'Play'}>
        {isPlaying ? <FontAwesomeIcon icon={faPauseCircle} /> : <FontAwesomeIcon icon={faPlayCircle} />}
      </button>
      <WavesurferPlayer
				container="#waveform"
        height={30} 
				normalize={true} 
				fillParent={true} 
				width='100%' 
				barWidth={3}
        waveColor="#f0f2e0"
				progressColor='#068454'
        url={props.audioUrl}
        onReady={onReady}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
			<div className="current_time">{currentPlayTime}</div>
    </div>
  )
}
