import React, { useEffect, useRef } from 'react';
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function TextEditor({ setMessage, message, keyBindingFn, setIsReadyToSend }) {

    const EditorRef = useRef();
    
    useEffect(() => EditorRef?.current.focus(), [EditorRef]);

    const onEditorStateChange = (editorState) => {
        setMessage(editorState);
        let plainText = editorState && editorState.getCurrentContent().getPlainText();
        if (plainText?.trim() !== "") {
            setIsReadyToSend(true);
        } else {
            setIsReadyToSend(false);
        }
    };
   
    return (
        <Editor
            editorRef={(ref) => (EditorRef.current = ref)} 
            stripPastedStyles
            handlePastedText={() => false}
            editorState={message}
            handleReturn={keyBindingFn}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
        />
    )
}

export default TextEditor;

