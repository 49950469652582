import React from 'react'
import './banner.css'

function Banner(props) {
    const {
        menuName,
        header,
        image,
        description,
        color
    } = props.data;

    const RichDescription = <span dangerouslySetInnerHTML={{ __html: description}}></span>

    return (
        <div className="banner_wrapper">
            <div className="banner_container">
                <div className="banner_content">
                    <div className='banner_description'>
                        <h1 style={{color: color}}>{menuName}</h1>
                        <h2>{header}</h2>
                        <p>{RichDescription}</p>
                    </div>
                    <div className='banner_image'>
                        <img src={image} alt={header} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;