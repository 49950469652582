import { useState, useEffect, useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Placeholder from 'react-bootstrap/Placeholder';
import { useTranslation } from 'react-i18next';
import { getUserDetails } from '../suggestions/suggestionService';
import { LoginContext } from '../../context/loginContext';
import SuggestionView from '../suggestions/suggestionView';
import Toast from 'react-bootstrap/Toast';

function Footprint(props) {
  const [index, setIndex] = useState(0);
  const [footprints, setFootprints] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [errors, setErrors] = useState({ errorMsg: '', isError: false, statusCode: '', statusError:'' });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setFootprints(props.footprint)
  }, [props.footprint])

  function handleSelect(selectedIndex) {
    setIndex(selectedIndex);
  };

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  function openProfile(footprint) {
    const token_language = {
      language: i18n.language,
      accessToken
    };
    getUserDetails(token_language, footprint?.alias).then((response) => {
      if (response?.status === 200) {
        setUserDetails({
          viewed: false,
          name: footprint?.alias,
          id: response.data?.relationshipId,
          ...response.data
        }
        );
        setIsOpenProfile(true);
      } else{
        setErrors({ errorMsg: response.data.message , isError: true, statusCode: response?.status, statusError:  response.data.errors});
      }
    })
  }

  const closeProfile = () => {
    setIsOpenProfile(false)
  }

  const base64Image = (image) => {
    return `data:image/jpeg;base64,${image}`;
  };

  return (
    <div className="footprint_section">
      <h3> {t('member.footprint.footprint')}</h3>
      <Carousel className='carousal' activeIndex={index} onSelect={handleSelect} interval={null}>
        {footprints != null ? 
        footprints?.map((footprint) => {
          return <Carousel.Item key={footprint.name} className='footpintImg' >
            <img src={'data:image/png;base64,' + footprint.image} alt={footprint.name}></img>
            <Carousel.Caption>
              <div 
              onClick={() => openProfile(footprint)}
              >
                <h4>{footprint.name}</h4>
                <p>from {footprint.alias}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        }) :
            <Carousel.Caption>
              <Placeholder animation="glow">
                <Placeholder xs={5} />
                <Placeholder xs={4} />
                <Placeholder xs={4} />{' '}
                <Placeholder xs={5} /> <Placeholder xs={8} />
              </Placeholder>
            </Carousel.Caption>
        }
      </Carousel>
      <Toast className='toaster_freetext' bg={'danger'} 
      onClose={() => setErrors({ errorMsg: '', isError: false , statusCode: ''})} 
      show={errors?.isError}
       delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Status Code:  {errors?.statusCode}</strong>
        </Toast.Header>
        <Toast.Body className={'text-white'}>
          <p>{errors?.statusError || ''}
            {errors?.errorMsg ? (
              <p>{errors?.errorMsg}</p>
            ) : null}
          </p>
        </Toast.Body>
      </Toast>
      {isOpenProfile ?
        <SuggestionView
          isParentSuggestion={false}
          show={isOpenProfile}
          base64Image={base64Image}
          handleClose={closeProfile}
          suggestion={userDetails}
        /> : ''}
    </div>
  )
}

export default Footprint;