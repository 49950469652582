import { useEffect, useState } from "react";
import Spinner from '../../components/spinner';
import { useTranslation } from "react-i18next";
import { doLogin, getMemberDetails } from "./loginService";
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentURL } from '../registrationProcess/registrationProcessService'

function AutoLogin() {
    const { t } = useTranslation();
    let {
        dispatch
    } = useContext(LoginContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        localStorage.removeItem('accessToken')
        const userName = location.search.split("&")[0].split("=")[1]
        const password = location.search.split("&")[1].split("=")[1]
        loginSubmit(userName, password);
    }, [])


    const loginSubmit = (userName, password) => {
        const loginName = userName
        const loginPayload = {
            loginName,
            password,
        }
        doLogin(loginPayload).then((res) => {
            if (res.status === 200) {
                localStorage.setItem('accessToken', res.data.accessToken);
                dispatch({ type: 'ACCESS_TOKEN', data: res.data.accessToken })
                memberData(res.data.accessToken, res.data.pendingPrePayment, res.data.pendingDirectDebitPayment);
                dispatch({ type: 'LOGIN', data: res.data });
            } else{
                navigate('/');
            }
        })
    }

    const memberData = (accessToken, pendingPrePayment, pendingDirectDebitPayment) => {
        getMemberDetails(accessToken).then((response) => {
            if (response?.status === 200) {
                dispatch({ type: 'MEMBER_DETAILS', data: response.data });
                if (response.data.memberStatus === 'REGISTRATION') {
                    getCurrentURL(accessToken).then((res) => {
                        dispatch({ type: 'CURRENT_URL', data: res.data });
                        navigate('/registration', { replace: true });
                    })
                    return;
                }
                else if (response.data.memberStatus === 'REGISTERED' && pendingPrePayment) {
                    navigate('/payment', { replace: true });
                    return;
                }
                else if (response.data.memberStatus === 'REGISTERED') {
                    if (!pendingDirectDebitPayment && (response.data.subscriptionState === 'EXPIRING' || response.data.subscriptionState === 'ACTIVE')) {
                        navigate('/home');
                        return;
                    }
                    else if (!pendingDirectDebitPayment && (!response.data.subscriptionState || response.data.subscriptionState === 'EXPIRED' || response.data.subscriptionState === 'CANCELED')) {
                        navigate('/inactive/subscription', { replace: true })
                        return;
                    }
                    else if (pendingDirectDebitPayment) {
                        navigate('/payment', { replace: true });
                        return;
                    }
                }
                else if (response.data.memberStatus === 'CANCELED' || response.data.memberStatus === 'ADMIN_CANCELED') {
                    navigate('/cancellation', { replace: true })
                    return;
                }
            }
        })
    }

    return (
        <>
            <div className="login_box">
                <div id="auto_login" className="login_main_container ">
                    <div>
                        <Spinner />
                    </div>
                </div>
            </div>
            <div className="legal">
                <a className="legal_link" target="_blank" href="https://www.gleichklang.de/impressum/" aria-label="{t('member.footer.imprint')}">{t('member.footer.imprint')}</a> | <a className="legal_link" target="_blank" href="https://www.gleichklang.de/datenschutz/" aria-label="{t('member.login.dataProtection')}">{t('member.footer.dataProtection')}</a> | <a className="legal_link" target="_blank" href="https://www.gleichklang.de/agb/" aria-label="{t('member.footer.terms')}">{t('member.footer.terms')}</a>
            </div>
        </>
    )
}

export default AutoLogin;