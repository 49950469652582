import React, { useRef, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import GlkHeader from '../../../components/Header/GlkHeader';
import Banner from '../../../components/banner/banner';
import suggestionIcon from '../../../assets/images/suggestion.png';
import './messenger.css';
import useWebSocketComponent from './webSocket';
import { LoginContext } from '../../../context/loginContext';
import UsersList from './usersList';
import ChatHistory from './chatHistory';
import Spinner from '../../../components/spinner';
import { useTranslation } from 'react-i18next';

function Messenger() {
  const { sendMessage, userDetails, chatRoom, usersChat, setIsLoading, isLoading} = useWebSocketComponent();
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [currentAvatar, setCurrentAvatar] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [recordsRemaining, setRecordsRemaining] = useState(true);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [isMessageError, setIsMessageError] =  useState(false);
  const [userListPageNo, setUserListPageNo] = useState(1);
  const [isFileLoader, setIsFileLoader] = useState(false);
  const [isOpenMessage, setIsOpenMessage] = useState(false);
  const [isMembersLoading, setIsMembersLoading] = useState(true);
  const [isFileResponseReceived, setIsFileResponseReceived] = useState(false);
  const [filters, setFilters] = useState({
    friendship: false,
    partnership: false,
    contactList: false,
    hideUnavailable: true,
    unanswered: false,
    unread: false,
  });

  const {
    loginData: { accessToken },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const getUsers = {
      type: "AUTH",      
      token: accessToken,
    };
    sendMessage(JSON.stringify(getUsers));
  }, [accessToken]);

  useEffect(() => {
    if (chatRoom?.subType === 'CHAT_ROOMS') {
      setIsMembersLoading(chatRoom?.recordsRemaining)
      if(chatRoom?.recordsRemaining){
        setUserListPageNo(prev => prev + 1);
      }      
      setUsers((prevUsers) => {
        let newUsers = chatRoom?.chatRooms;
        return [...new Set([...prevUsers, ...newUsers])]
      });

      if(chatRoom?.chatRooms){
        setIsUserAvailable(false)
      }     
    }
  }, [chatRoom?.chatRooms])

  useEffect(() => {
    if (userDetails?.error) {
      setIsMessageError(true)
      setIsFileLoader(false);
    }
  }, [userDetails])
  
  useEffect(() => {    
    if (usersChat?.fileType === 'IMAGE' || usersChat?.fileType === 'FILE') {      
      setAttachmentFiles((prevAttachments) => [usersChat, ...prevAttachments]);
      setIsFileResponseReceived(false);
    }

    if (usersChat?.subType === 'CHAT_HISTORY' || usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE") {
      if (usersChat?.subType === "CHAT_HISTORY") {
        setRecordsRemaining(usersChat?.recordsRemaining);
      }

      setIsFileLoader(false);

      const newMessages =
      usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE"
          ? [usersChat]
          : usersChat?.messages;

      setChats((prevChats) => {
        const isChatHistory = usersChat?.subType === "CHAT_HISTORY";
        const currentUserId = currentUser?.userId;
        const newMessage = newMessages[0];
        const isCurrentUserInvolved =
        newMessage && (currentUserId === newMessage.receiverId || currentUserId === newMessage.senderId);

        const uniqueChats = (messages) => [...new Map(messages.map((msg) => [msg.id, msg])).values()]; 

        const targetUserId = isCurrentUserInvolved
        ? currentUserId
        : newMessage?.senderId || currentUserId;
        const currentUserChatIndex = prevChats.findIndex((chat) => chat.id === targetUserId);
                
        let updatedChat = {
          id: targetUserId,
          pageCount: pageNo,
          chat: [],
        };
      
        if (currentUserChatIndex !== -1) {
          updatedChat.chat = prevChats[currentUserChatIndex].chat;
        }
      
        if (isChatHistory) {
          updatedChat.chat = uniqueChats([...updatedChat.chat, ...newMessages]);
        } else if (isCurrentUserInvolved) {          
          updatedChat.chat = uniqueChats([...newMessages, ...updatedChat.chat]);
        } else {
          if (currentUserChatIndex !== -1) {
            const updatedChats = [...prevChats];
            updatedChats[currentUserChatIndex].chat = uniqueChats([
              usersChat,
              ...prevChats[currentUserChatIndex].chat,
            ]);
            return updatedChats;
          } else {
            const newChatEntry = {
              id: newMessage?.senderId,
              pageCount: 1,
              chat: uniqueChats([usersChat]),
            };
            return [newChatEntry, ...prevChats];
          }
        }
      
        if (currentUserChatIndex !== -1) {
          const updatedChats = [...prevChats];
          updatedChats[currentUserChatIndex] = updatedChat;
          return updatedChats;
        } else {
          return [...prevChats, updatedChat];
        }
      });    
    }

    if (usersChat?.subType === "DELETE") {
      setChats((prevMessages) => 
        prevMessages.filter((msg) => msg.id != usersChat.messageId)
      );
    }
  
  }, [usersChat]);  

  const getUserList = (page) => {
    if (!users.length) {
      setIsUserAvailable(true);
    }
    const getUsers = {
      type: "API",
      subType: "CHAT_ROOMS",
      pageNo: page,
      language: i18n.language
    };
    sendMessage(JSON.stringify(getUsers));
  }

useEffect(() => {  
  if (isMembersLoading || isFileLoader && !currentUser?.userId) return;

  const markMesgRead = {
    type: "API",
    subType: "MARK_READ",
    targetUserId: currentUser?.userId,
  };

  const timeoutId = setTimeout(() => {
    setIsLoading(true);
    sendMessage(JSON.stringify(markMesgRead));
  }, 2000);

  return () => clearTimeout(timeoutId);
}, [currentUser?.userId, isMembersLoading]);

    
  useEffect(() => {
    getUserList(userListPageNo);
  }, [userListPageNo]);

  const data = {
    image: suggestionIcon,
    menuName: t('messenger.suggestions'),
    header:  t('messenger.message'),
    description:  t('messenger.messagedescription'),
    color: "rgb(89, 117, 28)",
  };

  const fetchChatHistory = (page) => {    
    const message = {
      type: "API",
      subType: "CHAT_HISTORY",
      pageNo: page,
      targetUserId: currentUser?.userId,
      pageSize: 20,
    };
    setIsLoading(true);
    if (currentUser?.userId) {
      sendMessage(JSON.stringify(message));
    }
  };

  function doesIdExist(idToCheck) {
    return chats.some((chat) => chat.id === idToCheck);
  };

  useEffect(() => {    
    if (doesIdExist(currentUser?.userId)) {
      return;
    } else {
      fetchChatHistory(pageNo);
    }
  }, [currentUser]);

  useEffect(() => {
    const currentChat = chats.find(
      (chat) => chat.id === currentUser?.userId
    )?.pageCount;
    
    if (pageNo <= currentChat) {
      setPageNo(currentChat);
    }else{
      setPageNo(pageNo);
      fetchChatHistory(pageNo);
    }
  }, [pageNo]);  

  const chatContainerRef = useRef(null);
  const lastContactElementRef = useCallback(
    (node) => {
      if (chatContainerRef.current) chatContainerRef.current.disconnect();
      chatContainerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && recordsRemaining) {
            setPageNo((prevMessagePage) => prevMessagePage + 1);
        }
      });
      if (node) chatContainerRef.current.observe(node);
    },
    [recordsRemaining]
  );

  const chatOverviewRef = useRef(null);
  const lastOverviewElementRef = useCallback(
    (node) => {
      if (chatOverviewRef.current) chatOverviewRef.current.disconnect();
      chatOverviewRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && recordsRemaining) {
            setPageNo((prevMessagePage) => prevMessagePage + 1);
        }
      });
      if (node) chatOverviewRef.current.observe(node);
    },
    [recordsRemaining]
  );

  const filteredUsers = useMemo(() => {
    const filtered = users.filter(user => {
      const group1 = filters.friendship || filters.partnership || filters.contactList;
      const group1Match =
        (filters.friendship && user.friendship) ||
        (filters.partnership && user.partnership) ||
        (filters.contactList && user.contactListUser);
      const group2 = filters.hideUnavailable || filters.unanswered || filters.unread;
      const group2Match =
        (!filters.hideUnavailable || user.available) &&
        (!filters.unanswered || user.unanswered) &&
        (!filters.unread || user.unread);
      return (!group1 || group1Match) && (!group2 || group2Match);
    });
    return Array.from(new Set(filtered.map(user => user.relationshipId)))
      .map(id => filtered.find(user => user.relationshipId === id));
  }, [users, filters]); 

  return (
    <div>
      <GlkHeader />
      <main className="main_wrapper messenger_wrapper">
        <Banner data={data} />        
          <div className='messenger_container questionaire_in_menubar'>
            {!isUserAvailable  ?
              <div className='m_suggestion_chat'> 
                  <UsersList
                      chats={chats}
                      users={filteredUsers}
                      isLoading={isLoading}
                      isFileLoader={isFileLoader}
                      setIsFileLoader={setIsFileLoader}
                      currentUser={currentUser}
                      currentAvatar={currentAvatar}
                      sendMessage={sendMessage}
                      setPageNo={setPageNo}
                      isUser={users?.length == 0} 
                      isUserAvailable={isUserAvailable}
                      isFilterUser={filteredUsers.length}
                      setFilters={setFilters}
                      filters={filters}
                      setCurrentUser={setCurrentUser}
                      setCurrentAvatar={setCurrentAvatar}
                      isFileResponseReceived={isFileResponseReceived} 
                      isOpenMessage={isOpenMessage} 
                      setIsOpenMessage={setIsOpenMessage}
                    />                    
                    <ChatHistory
                      chats={chats?.find((user) => user.id === currentUser?.userId)?.chat || []}
                      setUsers={setUsers}
                      userDetails={userDetails}
                      isMessageError={isMessageError}
                      isFileLoader={isFileLoader}
                      setIsFileLoader={setIsFileLoader}
                      setIsMessageError={setIsMessageError}
                      sourceType={'MESSENGER'}
                      sendMessage={sendMessage}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      isFilterUser={filteredUsers.length == 0}
                      currentAvatar={currentAvatar}
                      attachmentFiles={attachmentFiles}
                      chatContainerRef={lastContactElementRef}
                      chatOverviewRef={lastOverviewElementRef} 
                      setIsFileResponseReceived={setIsFileResponseReceived}
                      isFileResponseReceived={isFileResponseReceived} 
                      isLoading={isLoading} 
                      isOpenMessage={isOpenMessage} 
                      setIsOpenMessage={setIsOpenMessage}
                    />
              </div> : <Spinner />}
          </div>
      </main>
    </div>
  );
}

export default Messenger;
