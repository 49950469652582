import React, { useEffect, useState, useContext } from 'react'
import { Button, Col, Form } from 'react-bootstrap';
import { getSubscriptionOffers, saveSubscriptoionOffer } from './registrationProcessService';
import { LoginContext } from '../../context/loginContext';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import { autoFocusByID } from "../commonMethods";
import usePreventBack from './preventBack/preventBack'


function SubscriptionOffer(props) {
  const [offerDetails, setOfferDetails] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState();
  const [promoCode, setPromoCode] = useState(null);
  const [isPaymentError,setIsPaymentError] =useState(null)
  const [isPaymentOfferError,setIsPaymentOfferError] =useState(null)
  const [isPaymentMethodError,setIsPaymentMethodError] =useState(null)
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [promoCodeApplied, setPromoCodeApplied] = useState(null);
  const [isInitailOfferAvailable, setIsInitailOfferAvailable] = useState(false)
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const token_lang = {
    language: currentLang,
    accessToken
  }
  usePreventBack();

  const getOffers = (isPromocodeApplied) => {        
    if (isPromocodeApplied && (promoCode == null || promoCode == '')){
      setPromoCodeError(t('registration.subscription.no_promocode'))
      setPromoCodeApplied(null);
    }
    else{
      setPromoCodeError(null);
      setPromoCodeApplied(null);
      const productType = 'INITIAL_SUBSCRIPTION_OFFER';
      getSubscriptionOffers(token_lang, productType, promoCode).then((res) => {
        if (res?.status === 200) {
          isPromocodeApplied && setPromoCodeApplied(t('registration.subscription.promo_applied'));
          isPromocodeApplied && !res?.data?.products?.length && setIsInitailOfferAvailable(t('registration.subscription.no_product_available'))
          setOfferDetails(res?.data);
        }else{
          setPromoCodeError(res.data.message);
          window.scrollTo(0,0)
        }
      })
        .catch((err) => {
          console.log('error', err);  
        })
    }
  }

  useEffect(() => {
    getOffers(false);
  }, [i18n.language])

  useEffect(() => {
    if (!promoCode) {
      getOffers(false)
      setIsInitailOfferAvailable('')
    };
  }, [!promoCode])
  
  const onSaveSubscriptionOffer = () => {
    const body = {
      "productId": selectedOffer,
      "actionCode": promoCode,
      "paymentMethod": paymentMethod
    }
    if (paymentMethod === undefined || paymentMethod === ""){
      setIsPaymentOfferError(null)
      setIsPaymentMethodError(t('registration.subscription.paymentMethodError'));
      window.scrollTo(0, 400)
    }
    else if(selectedOffer === null){
      setIsPaymentMethodError(null)
      setIsPaymentOfferError(t('registration.subscription.paymentOfferError'));
      window.scrollTo(0, 1000)
    }
    else{
      saveSubscriptoionOffer(body, accessToken).then((res) => {
        if (res?.status === 200) {
          // dispatch({ type: 'CURRENT_URL', data: res.data });
          props?.setQuestionaireId(res?.data?.nextStep);
          props?.setPaymentMethod(paymentMethod)
        }else{
          setIsPaymentError(res.data.message);
          window.scrollTo(0,0)
        }
      })
        .catch((err) => {
        })
    }
  }

  const handleTapSelect = (e) => {
    if (!e.target.value) {
      if (e.type === "keydown" && e.keyCode === 9 && e.target.id === 'formGridpayment') {
        autoFocusByID(e.target.id);
        setIsPaymentMethodError(true);
        setIsPaymentError(t('registration.subscription.prepaymentmethod'))
      }
    }
  }

  const handleTapOffer = (e) => {
    if (e.type === "keydown" && e.keyCode === 9 && e.target.type !== "offer") {
      if (!e.target.checked) {
        autoFocusByID(e.target.id);
        setIsPaymentOfferError(t('registration.subscription.paymentOfferError'));
      }
    }
  };

  const autofillAnswers = () => {
    setPaymentMethod('DIRECT_DEBIT');
    if (offerDetails.length > 0) {
      setSelectedOffer(offerDetails[0].id);
    };
  }

const selectPaymentMethod = (paymentMethodValue) =>{
  setPaymentMethod(paymentMethodValue)
  if(paymentMethodValue !== undefined || paymentMethodValue !== ""){
    setIsPaymentError(false)
    setIsPaymentMethodError(null)
  }
}
const selectPaymentOffer = (offerId) =>{
  setSelectedOffer(offerId)
  if(offerId !== null){
    setIsPaymentOfferError(null);
  }
}

  return (
    <div>
    <div className="looking_for">
      <div className="questionnaireHeader">
        <h3 className="lineH3 text-center"><strong>{t('member.preregistration.finalizeHeading')}</strong> &ndash; {t('member.preregistration.step1of2')}</h3>
        <h4>{t('registration.subscription.choosePaymentMethod')}</h4>
        <p>{t('registration.subscription.choosePaymentDescription')}</p>
      </div>
    </div>
     {process.env.REACT_APP_ENV != 'PROD' ?
        <div className="mt-3 mb-3 auto_fill_wrapper">
          <Button
            className='mx-5'
            variant="success"
            onClick={autofillAnswers}
          >
            {t('registration.buttons.autofill')}
          </Button>
          <Button
            className='mx-5'
            variant="primary"
            onClick={() => onSaveSubscriptionOffer()}
          >
            {t('registration.buttons.next')}
          </Button>
        </div> : null}
        <div className='from_control questionair_container subscription_offer'>
          <div className='questionair_header'>
            <h3 className='questionair_heading'>{t('registration.subscription.heading')}</h3>
          </div>
          <div className="mt-3 partnershippost_btn questions_wrapper">
            <p className='heading_description'>{t('registration.subscription.description')}</p>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>{t('registration.subscription.promo')}</Form.Label>
            <div className="promocode_container">
              <Form.Control
                aria-label={t('member.preregistration.promoCodeDesc')}
                onChange={(e) => {setPromoCode(e.target.value);}}
                type="text"
                value={promoCode}     
              />
              <Button
                onClick={() => getOffers(true)}
                variant="primary"
              >
                {t('registration.subscription.apply')}
              </Button>
            </div>
            {promoCodeError != null ? (
                <Alert onClose={() => setPromoCodeError(null)} dismissible className='alert_questionaire' variant='danger'>{promoCodeError}</Alert>
              ) : null}
              {promoCodeApplied != null ? (
                <Alert onClose={() => setPromoCodeError(null)} dismissible className='alert_questionaire' variant='success'>{promoCodeApplied}</Alert>
              ) : null} 
          </Form.Group>
            <Form.Group as={Col} controlId="formGridpayment" className='mt-3'>
              <Form.Label>{t('registration.subscription.paymentmethod')}*</Form.Label>
              <Form.Select onChange={(e) => selectPaymentMethod(e.target.value)} 
                onKeyDown={(e) => handleTapSelect(e)} 
                className={isPaymentError || isPaymentMethodError ? "is-invalid" : ""}
                aria-label={isPaymentMethodError ? t('registration.subscription.prepaymentmethod') : ""}
                value={paymentMethod}
                >
                <option value=''>{t('registration.buttons.pleaseselect')}</option>
                {offerDetails?.paymentMethods?.map((paymentMethod) => (<>
                  <option value={paymentMethod?.name}>{paymentMethod?.value}</option>
                </>))}                
              </Form.Select>          
            </Form.Group>   
              {isPaymentError ? (
                <Alert className='alert_questionaire' variant='danger'>{isPaymentError}</Alert>
              ) : isPaymentMethodError ? (
                <Alert className='alert_questionaire' variant='danger'>{isPaymentMethodError}</Alert>
              ) : null}
            <fieldset>  
              <legend className="subscription_legend">{t('registration.subscription.subscriptionOffer')}*</legend>
              <Form.Group className="mb-3 mt-3 offer_btn">
              {isInitailOfferAvailable != null && isInitailOfferAvailable != '' ? (
                <Alert className='alert_questionaire' variant='danger'>{isInitailOfferAvailable}</Alert>
              ) : null} 
                {offerDetails?.products?.map((offer, index) => (
                  <Form.Check
                    key={index}
                    id={`offer-${index}`}
                    className='msg_pre_checkbox mt-3'
                    type="radio"
                    name="offer"
                    label={<p dangerouslySetInnerHTML={{ __html: offer.value }} />} 
                    aria-label={isPaymentOfferError}
                    value={offer.id}
                    checked={selectedOffer === offer.id}
                    onChange={(e) => selectPaymentOffer(offer.id)}
                    onKeyDown={(e) => handleTapOffer(e)}
                  />
                ))}
              </Form.Group>
            </fieldset>
          </div>
          {isPaymentOfferError !== null ? (
              <Alert className='alert_questionaire' variant='danger'>{isPaymentOfferError}</Alert>
            ):null}  
          <div className="mt-3 step_one_next">
            <Button
              onClick={() => onSaveSubscriptionOffer()}
              className='mx-5'
              variant="primary"
            >
              {t('registration.buttons.booknow')}
            </Button>
          </div>
        </div>
    </div>
  )
}

export default SubscriptionOffer