import { useState } from "react";

export const numberOnlyWithDigit = (evt, digit) => {
    var val = evt.target.value;
    if(digit === 1) var reg = /^([0-9]|[0-9]+)/g;
    if(digit === 2) var reg = /^([0-9]?[0-9]|[0-9]+)/g;
    if(digit === 3) var reg = /^([0-9]?[0-9]?[0-9]|[0-9]+)/g;
    if(digit === 4) var reg = /^([0-9]?[0-9]?[0-9]?[0-9]|[0-9]+)/g;
    if(digit === 5) var reg = /^([0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]|[0-9]+)/g;
    val = reg.exec(val);
    if (val) {
        evt.target.value = val[0]
    } else{
        evt.target.value = "";
    }
}
export const autoFocusByID = (inputId, sTime) => {
    var setTime = 0;
    if(sTime) setTime = sTime;
    if (inputId) {
      window.setTimeout(function () {
        if (document.getElementById(inputId)) {
          document.getElementById(inputId).focus();
        }
      }, setTime);
    }
};

// This function the cursor will focus in first element on error input with class name 'is-invalid', When user submit the data
export const autoFocusFirstElement = (sClassName) => {
  var className = 'is-invalid';
  if(sClassName) className = sClassName;
  window.setTimeout(function () {
    const ele = document.getElementsByClassName(className);
    if(ele && ele.length > 0) {
      autoFocusByID(ele[0].id)
    }
  }, 10);
}


export const checkPasswordStrength = async (value, translate) => {
  const strength = await getPasswordStrength(value);
  let pLabel = 'none'
  let showVarient = "danger";
  switch (strength) {
    case 0:
      pLabel = translate('member.preregistration.strengthVeryWeak')
      showVarient = "danger";
      break;
    case 1:
    case 2:
      pLabel = translate('member.preregistration.strengthWeak')
      showVarient = "warning";
      break;
    case 3:
    case 4:
      pLabel = translate('member.preregistration.strengthStrong')
      showVarient = "info";
      break;
    case 5:
      pLabel = translate('member.preregistration.strengthVeryStrong')
      showVarient = "success";
      break;
    default:
      pLabel = 'none'
  }
  const pwdData = {
    strength: strength,
    label: pLabel,
    varient: showVarient
  }
  return pwdData
}

async function getPasswordStrength(password) {
  let strength = 0;
  if (!password) return '';
  if (password.length >= 8) strength += 1;
  if (/[a-z]/.test(password)) strength += 1;
  if (/[A-Z]/.test(password)) strength += 1;
  if (/\d/.test(password)) strength += 1;
  if (/[^A-Za-z0-9]/.test(password)) strength += 1;
  return strength;
}

export const escapeFilterCharacters = (value) => {
  return encodeURIComponent(value.replaceAll("-", "\\-").replaceAll(",", "\\,"));
}

