import './pendingPrePayment.css';
import React, { useEffect, useState, useRef, useContext } from 'react'
import GlkHeader from '../../components/Header/GlkHeader';
import { useTranslation, Trans } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhoneVolume,
    faSquarePhone,
    faEnvelope, 
		faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { getPaymentDetails, paymentMethodChange } from '../registrationProcess/registrationProcessService';
import Payment from '../registrationProcess/payment';
import PrePaymentDetails from './PrePaymentDetails';
import Toaster from '../Toaster/Toaster';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

function PendingPayment(props) {
    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    let navigate = useNavigate();
    const [prepaymentData, setPrepaymentData] = useState(null);
    const [isChangePaymentMethod, setIsChangePaymentMethod] = useState(false)
    const [toastMessage, setToastMessage] = useState()
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const headers = {
        language: currentLang,
        accessToken
    }
    
    useEffect(() => {
      if (
        !isChangePaymentMethod &&
        (prepaymentData?.prePaymentBankAccounts?.length == 0 ||
          prepaymentData == undefined ||
          prepaymentData == null)
      ) {
        getPaymentDetails(headers).then((result) => {
          if (result?.status === 200) {
            setPrepaymentData(result?.data);
            setIsChangePaymentMethod(!result?.data?.prepayment);
          } else {
            handleShowToast();
            setToastMessage(result?.data.message);
          }
        });
      }
    }, [isChangePaymentMethod]);


    const onNext = () => {
        navigate('/home');
        return;
    }

    function changeMethod() {
      const payload = {
        paymentMethod: "DIRECT_DEBIT",
        paymentRequest: null,
      };
      paymentMethodChange(headers, payload).then((result) => {
        if (result?.status === 200) {
          setIsChangePaymentMethod(true);
        }
      });
    }

    const toastRef = useRef();
    const handleShowToast = () => {
      const toastElement = toastRef.current;
      const options = {
        animation: true,
        autohide: true,
        delay: 3000,
      };
      if (toastElement) {
        const bootstrapToast = new bootstrap.Toast(toastElement, options);
        bootstrapToast.show();
      }
    };  

    return (
      <>
        <GlkHeader showMenuBar={false} />
        <div className="main_wrapper pending_payment_wrapper">
          <div className="left_area">
            <div className="home_banner info_banner">
              <div className="home_banner_content info_banner_content">
                <h1>
                  <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                  {t("registration.pendingPayment.mainHeading")}
                </h1>
                <h2>{t("registration.pendingPayment.header")}</h2>
                <p>
                  <Trans i18nKey="registration.pendingPayment.description" />
                </p>
              </div>
            </div>

            <div className="home_banner contact_banner">
              <div className="home_banner_content contact_banner_content">
                <h1>{t("registration.pendingPayment.contactTeamHeader")}</h1>
                <h2>{t("registration.pendingPayment.contactTeam")}</h2>

                <div className="contact_details_container">
                  <div className="contact_number_free">
                    <div className="contact_numberAndicon">
                      <div className="for_icon_circle">
                        <FontAwesomeIcon icon={faPhoneVolume} />
                      </div>
                      <div>
                        <a
                          className="text_white"
                          href="tel: +49 (0)511 51516660"
                          aria-label="call on 49 (0)511 51516660"
                        >
                          +49 (0)511 51516660
                        </a>
                        <span className="for_font_size">
                          {t("member.footer.contactTime")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="contact_number_paid">
                    <div className="contact_numberAndicon">
                      <div className="for_icon_circle">
                        <FontAwesomeIcon icon={faSquarePhone} />
                      </div>
                      <div>
                        <a
                          className="text_white"
                          href="tel: +49 (0) 1805 - 7772 3332"
                          aria-label="Call on +49 (0) 1805 - 7772 3332"
                        >
                          {" "}
                          +49 (0) 1805 - 7772 3332{" "}
                        </a>
                        <span className="for_font_size">(0.14 €/min)</span>
                      </div>
                    </div>
                  </div>
                  <div className="contact_email">
                    <div className="contact_numberAndicon">
                      <div className="for_icon_circle">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <a
                        className="text_white"
                        href="mailto:anfragen@gleichklang.de"
                        aria-label="mail to anfragen@gleichklang.de"
                      >
                        anfragen@gleichklang.de
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!isChangePaymentMethod ? (
            <PrePaymentDetails
             prepaymentData={prepaymentData} 
             scrollRef={scrollRef}  
             t={t}
             changeMethod={changeMethod}
             isChangePaymentMethod={isChangePaymentMethod}
             onNext={onNext}         
             />
          ) : (
            <div className="from_control questionair_container">
              <Payment isUserRegistered={true} setIsChangePaymentMethod={setIsChangePaymentMethod}/>
            </div>
          )}
        </div>
        <Toaster
        toastMessage={toastMessage}
        toastRef={toastRef}
      />
      </>
    );
}
export default PendingPayment;
