import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

function LogoutPopup(props) {
    const { t } = useTranslation();
    const onClose = () => {
        props.setShow(false);
    }
    return (
        <Modal show={props.show} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{props.alias}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    <Form.Group className="mb-3" controlId="popupForm.ControlInput1">
                        <Form.Label>
                            {t('member.header.logoutConfirmationText')}
                        </Form.Label>
                    </Form.Group>
                </Form>
            </ModalBody>
            <Modal.Footer>
                <Button variant="outline-danger"
                    onClick={(e) => onClose()}
                >
                    {t('registration.buttons.no')}
                </Button>
                <Button variant="secondary"
                    onClick={(e) => props.handleLogout(e)}
                >
                    {t('registration.buttons.yes')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default LogoutPopup;