import React, { useEffect, useContext, useState } from 'react';
import partnership from '../../assets/images/partnership.png';
import { LoginContext } from '../../context/loginContext';
import PersonalDetails from './personalDetails.js';
import { getQuestionaireURL, getQuestionaireId } from './personalDetailsService.js';
import { useTranslation } from 'react-i18next';

function RegionalSearchP() {
  const [questionaire, setQuestionaire] = useState();
  const [reloadQuestionaire, setReloadQuestionaire] = useState(false);
  const { t, i18n } = useTranslation();


  const data = {
    "image": partnership,
    "menuName": t('member.banner.partnership'),
    "header": questionaire?.name,
    "description": questionaire?.description,
    "color": "#a01313"
  }

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const currentLang = i18n.language;

  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    getQuestionaireId(token_lang).then((response) => {
      if (response?.status == 200) {
        const questionaireId = response?.data?.questionnaireAndIds?.["partner.region_question"]?.key;
        getQuestionaireURL(token_lang, questionaireId).then((res) => {
          if (res?.status === 200) {
            setQuestionaire(res?.data)
          }
        }).catch((error) => {
        })
      }
    })
  }, [currentLang, reloadQuestionaire])

  return (
    <div>
      <PersonalDetails
        data={data}
        questionaire={questionaire}
        setReloadQuestionaire={setReloadQuestionaire}
      />
    </div>
  )
}

export default RegionalSearchP;