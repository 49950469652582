import './GlkHeader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faUserPlus, faEnvelope, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/glk_member_area.svg';
import icon_logo from "../../assets/images/glk_member_area_mobile.svg";
import { useTranslation } from "react-i18next";
import i18n from '../../i18n/i18n';
import {  useNavigate , useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {getHomeDetails, generateUserPin} from '../../pages/home/homeServices.js';
import { useContext, useEffect, useState , useLayoutEffect } from 'react';
import { LoginContext } from '../../context/loginContext';
import Menubar from '../../pages/home/menuBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import userIcon from '../../assets/images/user-icon01.png';
import LogoutPopup from './logoutPopup.js';
import Modal from 'react-bootstrap/Modal';

function GlkHeader(props) {
  const [homeDetails, setHomeDetails] = useState(null);
	const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [userPin, setUserPin] = useState(null);
  const [showUserPinPopup, setShowUserPinPopup] = useState(false)
  const { t } = useTranslation();
  const languageCode = i18n.language;
  
  let navigate = useNavigate();
  let {
    loginData: {
      memberDetails,
			accessToken
    }, 
    dispatch
  } = useContext(LoginContext);
  
  const token_lang = {
    language: languageCode,
    accessToken
  }

  useEffect(() => {
    getHomeDetails(token_lang).then((res) => {
      if (res?.status === 200) {
        setHomeDetails(res.data);  
      }
    })    
  }, [languageCode, accessToken]);
	
	const toggleMobileMenu = () => {
		setMobileMenuVisible(!mobileMenuVisible);
  };

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    dispatch({ type: 'SET_LANGUAGE', data: value });
  }

  const handleLogout = (e) => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGIN', data: null });
    dispatch({ type: 'MEMBER_DETAILS', data: null });
    dispatch({ type: 'IS_CONFIRM_MAIL_POPUP', data: true });
    navigate('/')
    }

  const location = useLocation();

  const getUserPin = () => {
    generateUserPin(token_lang).then((response) => {
      if (response?.status === 200) {
        setUserPin(response.data);
        setShowUserPinPopup(true)
      }
      else {
        setUserPin(null);
        setShowUserPinPopup(true)
      }
    })    
  }

  const closeUserPinPopup = () => {setShowUserPinPopup(false)}
	
	useLayoutEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  }, [location.pathname]);

  function navigateToPage(path) {
    const pathUrl = {
      suggestions: '/suggestions/suggestions',
      messenger: '/suggestions/messenger',
      overview: '/',
    };
  
    navigate(pathUrl[path]);
  }

  return (
    <>
    <header className="app_header">      
      <div onClick={() => navigateToPage('overview')}>
        <img className='full_logo' src={logo} alt="Gleichklang logo" />
        <img className='small_logo' src={icon_logo} alt="Gleichklang Icon" />
      </div>
			
	  {memberDetails?.memberStatus === 'REGISTERED' ? 
	  (
      <div className='member_header'>	
				<div className='home_btns'>		
					<Button  onClick={() => navigateToPage('suggestions')} className='suggest_btn' variant="success">
						<FontAwesomeIcon icon={faUserPlus} />
						{t('member.menubar.suggestions')}
						<Badge bg="warning">{homeDetails?.suggestions}</Badge>
						<span className="visually-hidden">{t('member.header.unread_suggestions')}</span>
					</Button>
					<Button onClick={() => navigateToPage('messenger')} className='msg_btn' variant="primary">
						<FontAwesomeIcon icon={faEnvelope} />
						{t('member.menubar.messages')}
						<Badge bg="warning">{homeDetails?.messages}</Badge>
						<span className="visually-hidden">{t('member.header.unread_messages')}</span>
					</Button>
				</div>
				<div className='header_member_info'>
					<span className='greeting'>
						{memberDetails ?
						<div onClick={() => getUserPin()}>{t('member.header.hello')} {memberDetails?.alias}</div>
						:
						null
					}
					</span>
					<div className='header_avatar'>
            <img className='avatar' src={homeDetails && homeDetails.avatar !==null ?  "data:image/jpeg;base64," + homeDetails.avatar : userIcon} alt={`Avatar ${memberDetails?.alias}`} />
					</div>
				</div>
      </div>
	  ) : ( '' )}	
		
      <div className='header_bts'>        
    {location.pathname == '/'
			? <div className='select_lag'>
        <label htmlFor="language" className="visually_hidden">{t('member.header.select_language')}</label>
          <select
            value={languageCode}
            id="language"
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            <option value='en'>English</option>
            <option value='de'>Deutsch</option>
          </select>
        </div> : location.pathname == '/pre-registration' ? 
        <button className='header_logout back_login' onClick={() => {setShow(true)}}>
						<FontAwesomeIcon icon={faChevronLeft} /> {t('member.header.back_to_login')}
				</button>
        :
				<>
          {location.pathname == '/registration' ?
            <span className='greeting register_user'>
              {memberDetails ?
              <div onClick={() => getUserPin()}>{t('member.header.hello')} {memberDetails?.alias}</div>
              :
              null
            }
            </span> : null
          }
					<button className='toggle_menu' aria-label="Menu" onClick={() => toggleMobileMenu()}><FontAwesomeIcon icon={faBars} /></button>
          <OverlayTrigger
          key='bottom'
          placement='bottom' 
          overlay={
            <Tooltip id={`tooltip-logout`}>
              {t('member.header.logout')}
            </Tooltip>
          }
        >
					<button className='header_logout' aria-label="Logout" onClick={() => {setShow(true)}}>
						<FontAwesomeIcon icon={faSignOutAlt} />
					</button>
        </OverlayTrigger>
				</>
        
    }
      </div>
    </header>
    {memberDetails?.memberStatus === 'REGISTERED' && (props?.showMenuBar == null || props?.showMenuBar)
    ? <Menubar mobileMenuVisible={mobileMenuVisible} handleLogout={() => {setShow(true)}} /> : '' }
      <LogoutPopup
        show={show}
        setShow={setShow}
        handleLogout={handleLogout}
        alias={memberDetails?.alias}
      />
      <div className="modal show">
          <Modal className="registration_modal" show={showUserPinPopup}>
            <Modal.Body>
              {userPin ? 
              <> 
                <p>{t('member.header.userPinUserName')}{memberDetails?.alias}</p>
                <p>{t('member.header.userPin')}{userPin?.pin}</p>
              </> 
              : <>
                <p>{t('member.header.userPinError')}</p>
              </>}
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={() => { closeUserPinPopup(); }}>
                {t('common.close')}</Button>
            </Modal.Footer>
          </Modal>
        </div>
    </>
  )
}

export default GlkHeader
