import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const usePreventBack = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      //navigate('/'); // Redirect to a specific route or stay in the current one
      return null;
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);
};

export default usePreventBack;
