import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';

function ChoiceQuestions(props) {
    const [radioAlert, setRadioAlert] = useState("")
    const [radioError, setRadioError] = useState(null);
    const [selectAlert, setSelectAlert] = useState('')
    const [selectError, setSelectError] = useState(null);

    const {
        data,
        questionaire,
        questionReference,
        errResponse,
        answers,
        setAnswers,
        onAlertOpen,
        qgroup,
        handleOnChange,
        handleOptionClick,
        setStoreQuestionaire
    } = props;

    const { t, i18n } = useTranslation();

    const validateTapRadio = (e, data) => {
        if (e.target.type === "radio" && e.type === "keydown" && e.keyCode === 9) {
            if (!e.target.checked && data.required) {
                setRadioError(t('registration.questionnaires.fieldRequired'));
                setRadioAlert(data.id);
                e.preventDefault()
            } else {
                setRadioAlert('');
                setRadioError(null)
            }
        }
    };

    const validateTabSelect = (e, data, selectedValue) => {
        if (e.type === "keydown" && e.keyCode === 9 && e.key === "Tab") {
            if (selectedValue === "" && data.required) {
                setSelectError(t('registration.questionnaires.fieldRequired'));
                setSelectAlert(data.id);
                e.preventDefault();
            } else {
                setSelectAlert('');
                setSelectError(null);
            }
        }
    };

    const selectStyles = () => ({
        container: (base) => ({
            ...base,
            width: '100%',
            maxWidth: '100%',
        }),
        singleValue: (base) => ({
            ...base,
            whiteSpace: 'normal',
        }),
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused
                ? '0 0 0 4px rgba(13, 110, 253, 0.25)'
                : 'none',
            borderColor: state.isFocused || state.isHovered
                ? '#86b7fe' : '#9ea5ab',
            transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
                borderColor: '#9ea5ab',
            },
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
                ? '#0d6efd'
                : '#fff',

            color: state.isFocused
                ? '#fff'
                : '#333',
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: '400',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 1000,
        }),
    });
    
    const changeCheckBox = (e, choice) => {
        if (e.target.checked) {
            handleOptionClick(data, choice.id, qgroup)
            handleOnChange()
        } else if (answers[data.id] && answers[data.id].answerValues.indexOf(choice.id) >= 0) {
            answers[data.id].answerValues.splice(answers[data.id].answerValues.indexOf(choice.id), 1)
            if (answers[data.id].answerValues.length == 0) delete answers[data.id];
            let shouldEnable = false
            Object.keys(data.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                data.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                    shouldEnable = answers[data.id] && answers[data.id].answerValues.indexOf(enablerOption) >= 0;
                    if (shouldEnable) { return true; }
                    return false;
                })
                const tempQn = { ...questionaire }
                tempQn.questionGroups.filter(qg => qg.id === qgroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                if (!shouldEnable) {
                    answers[key] = {};
                }
                setStoreQuestionaire(tempQn)
            })
            setAnswers(answers)
        }
    }

    let comboBox;
    if (data.choiceQuestion?.type === 'ComboBox')
        comboBox =
            <div key={`combo-${data.id}`} className='questionair_alignment'
                ref={(e) => questionReference(data, e)}
            >
                {data.enabledQuestion === true ?
                    <div>
                        <Form.Group className="mt-3 dropdown">
                            <span className="disriptionI_label">
                                {data?.description ?
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        onClick={() => onAlertOpen(data?.description)}
                                    /> : null}
                                <Form.Label htmlFor={data.id} id={`label-${data.id}`}>{data.name}
                                    <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                </Form.Label>
                            </span>
                            <Select
                                id={data.id}
                                inputId={data.id}
                                className={`dropdwon_select_questionair ${data.required && selectAlert == data.id ? 'is-invalid' : ''}`}
                                aria-label={data.required && selectAlert === data.id ? selectError : data.name}
                                aria-required={data.required ? 'true' : 'false'}
                                value={data.choiceQuestion.choices.find(choice => choice.id === answers[data.id]?.answerValues?.[0]) ?
                                        { value: answers[data.id]?.answerValues?.[0], label: data.choiceQuestion.choices.find(choice => choice.id === answers[data.id]?.answerValues?.[0]).value } : null
                                }
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleOptionClick(data, selectedValue, qgroup);
                                    handleOnChange();
                                    if (selectedOption) {
                                        setSelectAlert('');
                                    }
                                }}
                                onKeyDown={(e) => validateTabSelect(e, data, answers[data.id]?.answerValues?.[0] || "")}
                                options={[
                                    { value: '', label: t('registration.questionnaires.pleaseSelectOption') },
                                    ...data.choiceQuestion.choices.map(choice => ({
                                        value: choice.id,
                                        label: choice.value,
                                    })),
                                ]}
                                placeholder={t('registration.questionnaires.pleaseSelectOption')}
                                styles={selectStyles()} 
                                menuPlacement="auto"
                                menuPosition="fixed" 
                            />

                            {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                    name={`profileVisible${data.id}`}
                                    type="checkbox"
                                    label={t('common.visibleToOthers')}
                                    className='msg_pre_checkbox profile_visible'
                                    onClick={(e) => {
                                        if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                            answers[data.id].profileVisible = e.target.checked
                                            setAnswers(answers)
                                        }
                                    }}
                                >
                                </Form.Check> : null}
                        </Form.Group>
                        {data.required && selectAlert === data.id ? (<Alert className='alert_questionaire' variant="danger">{selectError}</Alert>) : (
                            <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse?.errorMessageByQuestionId?.[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse?.errorMessageByQuestionId?.[data.id]}</Alert>
                        )}
                    </div> : null}
            </div>

    let radioBtns;
    if (data.choiceQuestion?.type === 'Radio')
        radioBtns =
            <div key={`radio-${data.id}`} className='radio_section'
                ref={(e) => questionReference(data, e)}
            >
                {data.enabledQuestion === true ? <div>
                    <fieldset role="radiogroup"
                        aria-required={data.required ? 'true' : 'false'}
                        aria-labelledby={`legend-${data.id}`}>
                        <legend className="disriptionI_label inlineLegend" id={`legend-${data.id}`}>
                            <h4>
                                {data?.description ? (
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        onClick={() => onAlertOpen(data?.description)}
                                    />
                                ) : null}
                                {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                            </h4>
                        </legend>
                        <div id={data.id} className='radio_groups' role='radiogroup'>
                            {data.choiceQuestion.choices.map(choice => (
                                <Form.Group key={choice.id} className="category_f" id={`label-${choice.id}-${data.id}`}>
                                    <Form.Check
                                        checked={answers[data.id]?.answerValues?.includes(choice.id)}
                                        id={`radio-${choice.id}-${data.id}`}
                                        type="radio"
                                        name={data.id}
                                        label={choice.value}
                                        className='msg_pre_checkbox'
                                        onClick={(e) => {
                                            handleOptionClick(data, choice.id, qgroup)
                                            if (e.target.checked !== "") setRadioAlert("")
                                            handleOnChange()
                                        }}
                                        onKeyDown={(e) => validateTapRadio(e, data)}
                                    />
                                </Form.Group>
                            ))
                            }
                        </div>
                        {data.askVisibleToOthers === true ?
                            <Form.Check id={`profileVisible${data.id}`}
                                name={`profileVisible${data.id}`}
                                type="checkbox"
                                label={t('common.visibleToOthers')}
                                className='msg_pre_checkbox'
                                onClick={(e) => {
                                    if (answers?.[data.id].answerValues.length > 0) {
                                        answers[data.id].profileVisible = e.target.checked
                                        setAnswers(answers)
                                    }
                                }}
                            >
                            </Form.Check> : null}
                        {data.required && radioAlert == data.id ? (
                            <Alert className='alert_questionaire' variant="danger">{radioError}</Alert>
                        ) : (
                            <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse?.errorMessageByQuestionId?.[data.id] ? true : false}
                                variant="danger" id={`err_${data.id}`}>{errResponse?.errorMessageByQuestionId?.[data.id]}</Alert>
                        )}
                    </fieldset>
                </div> : null}
            </div>


    let checkBoxes;
    if (data.choiceQuestion?.type === 'Checkbox')
        checkBoxes =
            <div key={`check-${data.id}`} className='questionair_alignment'
                ref={(e) => questionReference(data, e)}
            >
                {data.enabledQuestion === true ? <div>
                    <fieldset>
                        <legend className="disriptionI_label inlineLegend">
                            <h4>{data?.description ?
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    onClick={() => onAlertOpen(data?.description)}
                                /> : null}
                                {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                            </h4>
                        </legend>
                        <div className='checkbox_questionair'>
                            {data.choiceQuestion.choices.map(choice => (
                                <Form.Group key={choice.id} className="category_f">
                                    <Form.Check
                                        defaultChecked={answers[data.id]?.answerValues.includes(choice.id)}
                                        id={`checkbox-${choice.id}-${data.id}`}
                                        name={data.id}
                                        type="checkbox"
                                        label={choice.value}
                                        className='msg_pre_checkbox'
                                        onClick={(e) => changeCheckBox(e, choice)}
                                    />
                                </Form.Group>
                            ))}
                            {data.askVisibleToOthers === true ?
                                <Form.Check id={`profileVisible${data.id}`}
                                    name={`profileVisible${data.id}`}
                                    type="checkbox"
                                    label={t('common.visibleToOthers')}
                                    className='msg_pre_checkbox'
                                    onClick={(e) => {
                                        if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                            answers[data.id].profileVisible = e.target.checked
                                            setAnswers(answers)
                                        }
                                    }}
                                >
                                </Form.Check> : null}
                        </div>                        
                        <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse?.errorMessageByQuestionId?.[data.id] ? true : false}
                            variant="danger" id={`err_${data.id}`}>{errResponse?.errorMessageByQuestionId?.[data.id]}</Alert>
                    </fieldset>
                </div> : null}
            </div>

    return (
        <div>
            {comboBox}
            {radioBtns}
            {checkBoxes}
        </div>
    )
}

export default ChoiceQuestions;