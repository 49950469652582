import React, { useEffect, useContext, useState } from 'react';
import GlkHeader from '../../../components/Header/GlkHeader';
import partnership from '../../../assets/images/partnership.png';
import Banner from '../../../components/banner/banner';
import { getMyProfileData } from '../personalDetailsService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import './myprofile.css';
import Myprofile from './myProfile';

function MyprofilePartnersip() {
const [partnershipData, setPartnershipData] = useState(null);
const [refreshQuestionaire, setRefreshQuestionaire] = useState(false)

    let {
        loginData: {
            accessToken,
        },
    } = useContext(LoginContext);
    

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    }

    const data = {
        "image": partnership,
        "menuName": t('member.banner.partnership'),
        "header": t('member.banner.myprofile'),
        "description": t('member.banner.myprofile_desc'),
        "color": "#a01313"
    }

    useEffect(() => {
        const profilePartnershipURL = 'questionnaire/get/profile/partnership';

        getMyProfileData(token_lang, profilePartnershipURL).then((result) => {
            if (result?.status === 200) {
                setPartnershipData(result?.data)
                setRefreshQuestionaire(false)
            }
        })
    }, [currentLang, refreshQuestionaire])

    return (
        <div>
					<GlkHeader/>
					<main className="main_wrapper personal_details_wrapper Partnership">
            <Banner data={data} />           
                <Myprofile 
                    profileData={partnershipData}
                    type={'partnership'}
                    setRefreshQuestionaire={setRefreshQuestionaire}
                />
					</main>
        </div >
    )
}

export default MyprofilePartnersip;