import React, { useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import Login from "./pages/login/login";
import AutoLogin from "./pages/login/autoLogin";
import Home from "./pages/home/home";
import PreRegistration from "./pages/registration/membership";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import PersonalData from "./pages/membership/personalData";
import Subscription from "./pages/membership/subscription";
import MessageToGK from "./pages/membership/messageToGK";
import Statistics from "./pages/membership/statistics";
import Suggestions from "./pages/suggestions/suggestions";
import Lastseen from "./pages/suggestions/lastseen";
import Messenger from "./pages/suggestions/messenger/messenger";
import CommunityContacts from "./pages/suggestions/communityContacts/communityContacts.js";
import CommunityLists from "./pages/suggestions/communityContacts/communityLists.js";
import Registration from "./pages/registrationProcess/registration";
import { LoginContext } from '../src/context/loginContext';
import PersonalDetails from "./pages/personalDetails/personalDetails";
import MyprofileP from "./pages/personalDetails/myprofile/profileForPartnership";
import MyprofileF from "./pages/personalDetails/myprofile/profileForFriendship";
import SocialEnvironement from "./pages/personalDetails/socialEnvironement";
import ContactList from "./pages/personalDetails/contactList";
import Appearance from "./pages/personalDetails/appearance";
import OverallPersonality from "./pages/personalDetails/overallPersonality";
import Hobbies from "./pages/personalDetails/hobbies";
import SocialEngagement from "./pages/personalDetails/socialEngagement";
import MemberServey from "./pages/personalDetails/memberServey";
import Audio from "./pages/personalDetails/audio";
import GalleriesList from "./pages/personalDetails/galleriesList";
import WhatRUlooking4P from "./pages/personalDetails/whatRUlooking4P";
import ThoughtsOnRelationshipF from "./pages/personalDetails/thoughtsOnRelationshipF";
import AboutYouP from "./pages/personalDetails/aboutYouP";
import WhatRUlooking4F from "./pages/personalDetails/whatRUlooking4F";
import ThoughtsOnRelationshipP from "./pages/personalDetails/thoughtsOnRelationshipP";
import AboutyouF from "./pages/personalDetails/aboutYouF";
import RegionalSearchP from "./pages/personalDetails/regionalSearchP";
import RegionalSearchF from "./pages/personalDetails/regionalSearchF";
import PendingPayment from "./pages/pendingPayment/PendingPayment.js";
import ProcessPayment from "./pages/pendingPayment/paymentProgress";
import Cancellation from "./pages/cancellation/cancellation.js"
import NoActiveSubscription from './pages/pendingPayment/noActiveSubscription.js';
import MailConfirmation from './pages/mailConfirmation/mailConfirmation.js'
import Spinner from "./components/spinner.js";
import { createBrowserHistory } from 'history';
import PageNotFound from "./pages/pageNoFound/PageNotFound.js";

export default function Root(props) {  
  const [isLoading, setIsLoading] = useState(true);
  let { loginData: {
    memberDetails,
    accessToken
  }
  } = useContext(LoginContext)
  const history = createBrowserHistory();

  const memberStatus = memberDetails?.memberStatus;

  useEffect(() => {
    if (memberDetails == null && accessToken == null || memberDetails) {
      setIsLoading(false);     
  }
  }, [memberDetails]);

  if (isLoading) {
    return <div className="root_loader"><Spinner /></div>;
  }

  return (
    <Router history={history}>
      <Routes>
        {accessToken ? (
          <>
            {getRouteByMemberStatus(memberStatus)}
            {props?.isTokenExpired ? <>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </> : null}
          </>
        ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
        )}
        <Route exact path="/forgetpassword" element={<ForgotPassword />} />
        <Route exact path="/pre-registration" element={<PreRegistration />} />
        <Route exact path="/autoLogin" element={<AutoLogin />} />        
        <Route exact path="/link" element={<MailConfirmation />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

function getRouteByMemberStatus(memberStatus) {
  if(memberStatus) {
    switch (memberStatus) {
      case 'REGISTERED':
        return routeMemberArea();
      case 'REGISTRATION':
        return (
          <>
            <Route exact path="/registration" element={<Registration />} />
            <Route path="*" element={<Navigate to="/registration" />} />
          </>
        );
      case 'CANCELED':
      case 'ADMIN_CANCELED':
        return <Route exact path="/cancellation" element={<Cancellation/>}/>;          
      default:
        return <Route path="/" element={<Login />} />;
    }
  }
  return <Route exact path="/" element={<Login />} />;
}

function routeMemberArea() {
  return (
    <>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route exact path="/membership/personaldata" element={<PersonalData />} />
      <Route exact path="/membership/subcription" element={<Subscription />} />
      <Route exact path="/membership/message-to-gleichklang" element={<MessageToGK />} />
      <Route exact path="/membership/statistics" element={<Statistics />} />

      <Route exact path="/suggestions/suggestions" element={<Suggestions />} />
      <Route exact path="/suggestions/lastseen" element={<Lastseen />} />
      <Route exact path="/suggestions/community-contacts" element={<CommunityContacts />} />
      <Route exact path="/suggestions/community-contacts/nature" element={<CommunityLists />} />
      <Route exact path="/suggestions/community-contacts/project" element={<CommunityLists />} />
      <Route exact path="/suggestions/community-contacts/beruf" element={<CommunityLists />} />
      <Route exact path="/suggestions/community-contacts/treffen" element={<CommunityLists />} />

      <Route exact path="/suggestions/messenger" element={<Messenger />} />

      <Route exact path="/personal-details/community-contacts" element={<ContactList />} />
      <Route exact path="/personal-details/social-environement" element={<SocialEnvironement />} />
      <Route exact path="/personal-details/appearance" element={<Appearance />} />
      <Route exact path="/personal-details/overall-personality" element={<OverallPersonality />} />
      <Route exact path="/personal-details/hobbies" element={<Hobbies />} />

      <Route exact path="/personal-details/overall-personality" element={<PersonalDetails />} />
      <Route exact path="/personal-details/social-engagement" element={<SocialEngagement />} />
      <Route exact path="/personal-details/member-servey" element={<MemberServey />} />
      <Route exact path="/personal-details/gallery" element={<GalleriesList />} />
      <Route exact path="/personal-details/audio" element={<Audio />} />

      <Route exact path="/partnership/myprofile" element={<MyprofileP />} />
      <Route exact path="/partnership/what-are-you-looking-for" element={<WhatRUlooking4P />} />
      <Route exact path="/partnership/your-thoughts-on-relationship" element={<ThoughtsOnRelationshipP />} />
      <Route exact path="/partnership/regional-search" element={<RegionalSearchP />} />
      <Route exact path="/partnership/about-you" element={<AboutYouP />} />

      <Route exact path="/friendship/myprofile" element={<MyprofileF />} />
      <Route exact path="/friendship/what-are-you-looking-for" element={<WhatRUlooking4F />} />
      <Route exact path="/friendship/your-thoughts-on-relationship" element={<ThoughtsOnRelationshipF />} />
      <Route exact path="/friendship/regional-search" element={<RegionalSearchF />} />
      <Route exact path="/friendship/about-you" element={<AboutyouF />} /> 
      <Route exact path="/payment" element={<PendingPayment />} />
      <Route exact path="/process/payment" element={<ProcessPayment />} /> 
      <Route exact path="/inactive/subscription" element={<NoActiveSubscription />}/>
    </>
  )
}