import React, { useEffect, useContext, useState } from 'react';
import GlkHeader from '../../../components/Header/GlkHeader';
import friendrship from '../../../assets/images/friendrship.png';
import Banner from '../../../components/banner/banner';
import { getMyProfileData } from '../personalDetailsService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import './myprofile.css';
import Myprofile from './myProfile';

function MyprofilePartnersip() {
const [friendshipData, setFriendshipData] = useState(null);
const [refreshQuestionaire, setRefreshQuestionaire] = useState(false)

    let {
        loginData: {
            accessToken,
        },
    } = useContext(LoginContext);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
    }

    const data = {
        "image": friendrship,
        "menuName": t('member.banner.friendship'),
        "header": t('member.banner.myprofile'),
        "description": t('member.banner.myprofile_desc'),
        "color": "#16738b"
    }

    useEffect(() => {
        const profileFriendshipURL = 'questionnaire/get/profile/friendship';

        getMyProfileData(token_lang, profileFriendshipURL).then((result) => {
            if (result?.status === 200) {
                setFriendshipData(result?.data)
                setRefreshQuestionaire(false)
            }
        })
    }, [currentLang, refreshQuestionaire])

    return (
        <div>
					<GlkHeader/>
					<main className="main_wrapper personal_details_wrapper Friendship">
            <Banner data={data} />           
                <Myprofile 
                    profileData={friendshipData}
                    type={'friendship'}
                    setRefreshQuestionaire={setRefreshQuestionaire}
                />
					</main>
        </div >
    )
}

export default MyprofilePartnersip;