import React from 'react';
import './toaster.css'

const Toaster = (props) => {
  const {
    toastRef,
    toastMessage
  } = props;

  return (
      <div
        className="toast align-items-center text-bg-success border-0"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        ref={toastRef}
      >
        <div className="d-flex">
          <div className="toast-body">{toastMessage}</div>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
  );
};

export default Toaster;
