import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo, faMultiply
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from 'react-select';
import {
    EditorState,
    convertToRaw,
} from "draft-js";

function TextQuestions(props) {
    const [error, setError] = useState(false);

    const {
        data,
        questionReference,
        errResponse,
        answers,
        setAnswers,
        onAlertOpen,
        qgroup,
        handleOnChange,
        handleOptionClick,
        updateErrorState,
        clearErrorState,
        errorMgsByID,
        setEditorStates,
        editorStates,
        setSelectedChildrenAge,
        selectedChildrenAge,
        freeTextError,
        freeTextId,
        numberOfChildren
    } = props;
    const { t, i18n } = useTranslation();

    const onFreetextSave = (htmlContent, data, qgroup, editorState) => {
        console.log('htmlContent', htmlContent);

        handleOptionClick(data, htmlContent, qgroup, editorState)
        if (htmlContent !== "") setError(false)
        handleOnChange()
    };

    const onEditorStateChange = (editorState, items) => {
        setEditorStates(prevStates => ({
            ...prevStates,
            [items.id]: editorState
        }));
    };

    const options = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
        { value: 11, label: 11 },
        { value: 12, label: 12 },
        { value: 13, label: 13 },
        { value: 14, label: 14 },
        { value: 15, label: 15 },
        { value: 16, label: 16 },
        { value: 17, label: 17 },
        { value: 18, label: 18 },
        { value: 19, label: 19 },
        { value: 20, label: 20 },
    ];

    const handleFreeText = (e, data) => {
        if (data.required) {
            const isEmpty = e.target.value === "";
            const freeTextLength = e.target.value.length < 200;
            if (isEmpty || freeTextLength) {
                updateErrorState(t(isEmpty ? 'registration.questionnaires.fieldRequired' : 'suggestions.freetextlimit'), data.id);
                return true;
            } else {
                clearErrorState(data.id);
                return false;
            }
        }
    }

    const handleTapBlockFreeText = (e, data) => {
        if (data.required && e.type === "keydown" && e.key === "Tab") {
            const isError = handleFreeText(e, data);
            if (isError) {
                e.preventDefault();
            }
        }
    }

    const handleSelect = (data, selectedOption, item, id) => {
        const updatedItems = [...(selectedChildrenAge || []), selectedOption.value];

        if (updatedItems.length <= numberOfChildren) {
            setSelectedChildrenAge(updatedItems);
            handleOptionClick(data, updatedItems, id);
        }
    };

    const handleRemove = (index, data, item, id) => {
        const updatedItems = [...selectedChildrenAge];
        updatedItems.splice(index, 1);
        setSelectedChildrenAge(updatedItems);
        handleOptionClick(data, updatedItems, id)
    };

    let textQuestions =
        <div
            key={`text-${data.id}`}
            className='questionair_alignment'
            ref={(e) => questionReference(data, e)}
        >
            {data.enabledQuestion === true ? <div>
                <Form.Group className="mt-3 text_question">
                    <span className="disriptionI_label">
                        {data?.description ?
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                onClick={() => onAlertOpen(data?.description)}
                            /> : null}
                        <Form.Label className="radio_label" htmlFor={data.id}>
                            {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                        </Form.Label>
                    </span>
                    {data?.textQuestion?.type == 'TextArea' ?
                        <>
                            {data.id != 204 ?
                                <Form.Control
                                    value={answers[data.id] != null && answers[data.id].answerValues != null && answers[data.id].answerValues.length > 0
                                        ? answers[data.id]?.answerValues[0] : ''}
                                    as="textarea"
                                    className={errResponse?.errorMessageByQuestionId?.[data.id] && 'is-invalid'}
                                    id={data.id}
                                    aria-label={errResponse?.errorMessageByQuestionId?.[data.id] && errorMgsByID}
                                    aria-required={data.required ? 'true' : 'false'}
                                    onChange={(e) => {
                                        handleOptionClick(data, e.target.value, qgroup)
                                        handleOnChange()
                                    }}
                                    onKeyDown={(e) => handleTapBlockFreeText(e, data)}
                                    onBlur={(e) => handleFreeText(e, data)}
                                />
                                :
                                <div className={data.required && error && freeTextError === data.id && "is-invalid"}>
                                    {selectedChildrenAge?.length != numberOfChildren ? (
                                        <Select
                                            options={options}
                                            onChange={(e) => handleSelect(data, e, qgroup, data.id)}
                                            className={data.required && error && freeTextError === data.id && "is-invalid"}
                                            placeholder="Select Age of children"
                                            isClearable={false}
                                            isSearchable={true}
                                            value={null}
                                        />
                                    ) : ""}
                                    <div className="selected-items">{console.log('selectedChildrenAge', selectedChildrenAge)
                                    }
                                        {selectedChildrenAge?.map((item, index) => (
                                            <div key={index} className="selected-item">
                                                {item}
                                                <div className='selected-item-button' onClick={() => handleRemove(index, data, item, data.id)}><FontAwesomeIcon icon={faMultiply} /></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </>
                        :
                        null
                    }
                    {data?.textQuestion?.type == 'RichTextArea' ?                   
                        <Editor
                            handlePastedText={() => false}
                            editorState={editorStates[data.id] || EditorState.createEmpty()}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(editorState) => {
                                onEditorStateChange(editorState, data);
                                let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
                                onFreetextSave(htmlContent, data, qgroup, editorState);
                            }}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                            }}
                        /> : null
                    }
                    {data.askVisibleToOthers === true ?
                        <Form.Check id={`profileVisible${data.id}`}
                            name={`profileVisible${data.id}`}
                            type="checkbox"
                            label={t('common.visibleToOthers')}
                            className='msg_pre_checkbox'
                            onClick={(e) => {
                                if (answers?.[data.id]?.answerValues.length > 0) {
                                    answers[data.id].profileVisible = e.target.checked
                                    setAnswers(answers)
                                }
                            }}
                        >
                        </Form.Check> : null}
                </Form.Group>
                {errResponse?.errorMessageByQuestionId?.[data.id] && (
                    <Alert className='alert_questionaire'
                        variant="danger" id={`err_${data.id}`}>{errResponse?.errorMessageByQuestionId?.[data.id]}</Alert>
                )}

                {freeTextError && freeTextId && freeTextId === data.id &&
                    <Alert className='alert_questionaire' id={data.id}
                        variant="danger" >
                        {freeTextError ? freeTextError : ""}
                    </Alert>}
            </div> : null}
        </div>

    return (
        <div>{textQuestions}</div>
    )
}

export default TextQuestions;