import React, { useState, useRef, useEffect, useContext } from 'react'
import './questionnaire.css';
import Button from 'react-bootstrap/Button';
import { questionaireAnswer, completeRegistration } from '../registrationProcessService'
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from "react-i18next";
import RegionalSearch from '../regionalSearch';
import DescriptionAlert from '../descriptionAlert';
import { autoFocusByID } from '../../commonMethods';
import usePreventBack from '../preventBack/preventBack';
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    EditorState,
    ContentState,
    convertFromHTML,
} from "draft-js";
import ChoiceQuestions from './ChoiceQuestions';
import NumberQuestions from './NumberQuestions';
import TextQuestions from './TextQuestions';

function Questionaire(props) {
    const [answers, setAnswers] = useState({})
    const [errResponse, setErrResponse] = useState({ errorMessageByQuestionId: {} })
    const [idescription, setIdescription] = useState();
    const [isIAlertOpen, setIsIAlertOpen] = useState(false);
    const [freeTextError, setFreeTextError] = useState(null);
    const [freeTextId, setFreeTextId] = useState(null);
    const [errorIds, setErrorIds] = useState([]);
    const [errorMgsByID, setErrorMgsByID] = useState("");
    const [editorStates, setEditorStates] = useState({});
    const [selectedChildrenAge, setSelectedChildrenAge] = useState([]);
    const [numberOfChildren, setNumberOfChildren] = useState()
    const [childrenAgeData, setChildrenAgeData] = useState(null)
    const [childrenObjectData, setChildrenObjectData] = useState(null)

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const questionRefs = useRef(new Map());
    const handleClose = () => setIsIAlertOpen(false);

    const onAlertOpen = (data) => {
        setIsIAlertOpen(true);
        setIdescription(data)
    }

    let {
        loginData: {
            accessToken
        },
        dispatch
    } = useContext(LoginContext);

    usePreventBack();

    useEffect(() => {
        if (errorIds.length > 0) {
            const firstErrorElement = questionRefs.current.get(errorIds[0]);
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0)
        }
    }, [errorIds]);

    useEffect(() => {
        props?.questionaire?.questionGroups?.filter((e) => e.id === 121).map((item, index) => {
            setChildrenObjectData(item);
            item?.questions?.filter((e) => e.id === 202).map((data) => {
                setNumberOfChildren(data.numberQuestion.answer);
            });
            item?.questions?.filter((e) => e.id === 204).map((data) => {
                setChildrenAgeData(data)
                setSelectedChildrenAge(
                    data.textQuestion?.answer
                        ?.split(',')
                        .map(item => parseInt(item.trim(), 10))
                        .filter(value => !isNaN(value))
                );
            });
        });
    }, [props]);

    const forQuetionaireAnswer = () => {
        const newErrorIds = [];
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((data) => {
                if (data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 || answers[data.id].answerValues?.includes(""))) {
                    newErrorIds.push(data.id.toString());
                }
            });
        });

        setErrorIds(newErrorIds);
        validateUIResubmit(newErrorIds)
        if (validateMulRangeResubmit()) return;

        const answerBody = {
            questionnaireId: props?.questionaire?.id,
            answers: answers
        };
        try {

            let cleanedAnswerBody = { ...answerBody };
            let cleanedRequest = {};
            for (let key in cleanedAnswerBody?.answers) {
                if (!cleanedAnswerBody?.answers[key]?.answerValues?.includes("")) {
                    cleanedRequest[key] = cleanedAnswerBody.answers[key];
                } else {
                    delete cleanedAnswerBody.answers[key];
                }
            }

            questionaireAnswer(cleanedAnswerBody, accessToken, currentLang)
                .then((res) => {
                    if (res?.status === 200) {
                        setAnswers({});
                        setErrResponse({});
                        dispatch({ type: 'CURRENT_URL', data: res.data });
                        if (res.data?.nextQuestionnaire !== null) {
                            props.setQuestionaireId(res.data?.nextQuestionnaire);
                        } else {
                            props.setQuestionaireId(res.data?.nextStep);
                        }
                    } else {
                        setErrResponse(res.data);
                    }
                })
        }
        catch (error) {
        }
    };

    const handleOptionClick = (question, answer, questionGroup, editorState) => {
        if (question?.id === 202 && 1 <= answer && answer <= 20) {
            setNumberOfChildren(answer);
            if (answer < selectedChildrenAge?.length) {
                setSelectedChildrenAge([]);
                handleOptionClick(childrenAgeData, [''], childrenObjectData);
            }
        }
        if (question.type === 'TextQuestion' && question.textQuestion.type === "RichTextArea") {
            setFreeTextError(null)
            setFreeTextId(question.id)
            let plainText = editorState?.getCurrentContent().getPlainText();

            if (question.required === true && plainText?.length >= 200 && errResponse?.errorMessageByQuestionId?.[question.id]) {
                let errResponseTemp = errResponse
                delete errResponseTemp.errorMessageByQuestionId[question.id]
                setErrResponse(errResponseTemp)
            }
        }
        if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            if (question.choiceQuestion.multiSelect === true) {
                let shouldEnable = false
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption ||
                            (answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0);
                        return shouldEnable;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    props.setStoreQuestionaire(tempQn)
                })

                if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                    answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
                    updatedAnswersValues(answers)
                } else if (!answers[question.id]) {
                    answers[question.id] = { "answerValues": [answer], "profileVisible": false }
                    updatedAnswersValues(answers)
                }
                return;
            }
            else {
                let shouldEnable = false;
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption
                        return shouldEnable;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    if (!shouldEnable) {
                        answers[key] = { "answerValues": [], "profileVisible": false }
                        hideChild(key, questionGroup.id, tempQn, answers)
                    }
                    props.setStoreQuestionaire(tempQn)
                })
            }
        }
        if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
            if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
            } else if (!answers[question.id]) {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers)
        } else {
            if (answers[question.id]) {
                if (question.id == 204) {
                    answers[question.id].answerValues = answer;
                }
                else {
                    answers[question.id].answerValues = [answer]
                }
            } else {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers)
        }
    };


    const handleOnChange = () => {
        const newErrorIds = [];
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((data) => {
                if (data.enabledQuestion && data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 || answers[data.id].answerValues?.includes(""))) {
                    newErrorIds.push(data.id.toString());
                }
            });
        });
        if (newErrorIds.length === 0) {
            setErrResponse({})
        }
    }

    const updatedAnswersValues = (answers) => {
        for (let key in answers) {
            if (Object.keys(answers[key]).length !== 0 && !answers[key]?.answerValues?.includes("")) {
                setAnswers({ ...answers })
            } else {
                delete answers[key];
            }
        }
    }

    const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
        const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
        const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
        if (parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                savedAnswers[key] = {}
                storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
            })
        }
    }

    const handleSetErrorIds = (qId, isError) => {
        const idString = qId.toString();
        if (isError) {
            if (!errorIds.includes(idString)) {
                const updatedErrorIds = [...errorIds, idString];
                setErrorIds(updatedErrorIds);
            }
        } else {
            const updatedErrorIds = errorIds.filter(a => a !== idString);
            setErrorIds(updatedErrorIds);
        }
    };

    const validateUIResubmit = (errorIds) => {
        for (const error of errorIds) {
            updateErrorState((t('registration.questionnaires.fieldRequired')), error)
        }
    }
    const validateMulRangeResubmit = () => {
        const ele = document.querySelectorAll('input[min]');
        let isSubmit = false;
        if (ele.length > 0) {
            const eventTarget = {};
            for (const element of ele) {
                const qId = element.id;
                const GqId = element.getAttribute('data-qid');
                eventTarget.target = { value: element.value, min: element.min, max: element.max }
                if (element.value) {
                    const res = validateMultipleRanges(eventTarget, qId, GqId)
                    if (res) {
                        isSubmit = true
                        handleSetErrorIds(qId, true);
                    } else {
                        isSubmit = false
                        handleSetErrorIds(qId, false);
                    }
                }
            }
            return isSubmit;
        }
        return false;
    }

    const updateErrorState = (message, qId) => {
        let updatedErrResponse = errResponse;
        if (!updatedErrResponse.errorMessageByQuestionId) updatedErrResponse = { errorMessageByQuestionId: {} }
        updatedErrResponse.errorMessageByQuestionId[qId] = message;
        setErrResponse(updatedErrResponse);
        setErrorMgsByID(message);
    }

    const clearErrorState = (qId) => {
        let errResponseTemp = errResponse;
        if (!errResponseTemp.errorMessageByQuestionId) errResponseTemp = { errorMessageByQuestionId: {} }
        if (errResponseTemp && errResponseTemp.errorMessageByQuestionId && errResponseTemp.errorMessageByQuestionId[qId]) {
            delete errResponseTemp.errorMessageByQuestionId[qId];
        }
        if (Object.keys(errResponseTemp.errorMessageByQuestionId).length === 0) errResponseTemp = { errorMessageByQuestionId: {} }
        setErrResponse(errResponseTemp);
        setErrorMgsByID('');
    };

    const validateMultipleRanges = (e, qId, GqId) => {
        let isFirstInput = false;
        let ele = null;
        if (document.querySelectorAll(`[data-qid='${GqId}']`).length > 0) {
            ele = document.querySelectorAll(`[data-qid='${GqId}']`)
        } else {
            ele = document.querySelectorAll(`[data-qid='${qId}']`)
        }
        if (ele.length > 0) {
            let range = [];

            ele.forEach((v, k) => {
                if (v.value) range.push({ value: Number(v.value), Gqid: GqId });
            });
            if (ele[0].id == e.target.id) isFirstInput = true;

            if (ele[0].value) {
                const minRange = e.target.min ? Number(e.target.min) : 0;
                const maxRange = e.target.max ? Number(e.target.max) : 0;
                const currentValue = Number(e.target.value);

                if (isFirstInput) {
                    if (currentValue < minRange || currentValue > maxRange) {
                        updateErrorState(t("registration.questionnaires.minMaxRangeError", { minRange, maxRange }), qId);
                        return true;
                    } else {
                        clearErrorState(qId);
                        return false;
                    }
                } else {
                    if (range.length >= 2) {
                        const firstValue = range[0].value;
                        const secondValue = range[1].value;
                        const resMinMax = secondValue - firstValue;
                        if ((GqId == 2 || GqId == 16) && resMinMax < 5) {
                            updateErrorState(t("registration.questionnaires.maximumRange"), ele[1].id);
                            return true;
                        }
                        if (GqId == 13 && resMinMax < 10) {
                            updateErrorState(t("registration.questionnaires.maximumHeight"), ele[1].id);
                            return true;
                        }

                        if (currentValue < minRange || currentValue > maxRange) {
                            updateErrorState(t("registration.questionnaires.maximumValue", { minRange, maxRange }), qId);
                            return true;
                        } else {
                            if (Number(ele[0].value) < Number(ele[0].min) || Number(ele[0].value) > Number(ele[0].max)) {
                                updateErrorState(t("registration.questionnaires.minMaxRangeError", { minRange, maxRange }), ele[0].id);
                                if (e.type === 'keydown' && e.keyCode === 9 && e.shiftKey !== true) {
                                    autoFocusByID(ele[0].id);
                                }
                                clearErrorState(qId);
                                return true;
                            }
                            clearErrorState(ele[0].id);
                            clearErrorState(qId);
                            return false;
                        }
                    } else {
                        if (currentValue < minRange || currentValue > maxRange) {
                            updateErrorState(t("registration.questionnaires.maximumValue", { minRange, maxRange }), qId);
                            return true;
                        }
                    }
                }
            } else {
                if (e.target.value && ele[0].value == '') {
                    let message = '';
                    if (GqId == 2 || GqId == 16) message = t("registration.questionnaires.minAge")
                    if (GqId == 13) message = t("registration.questionnaires.minHeight")
                    updateErrorState(message, qId)
                    return true;
                }
                clearErrorState(qId);
                return false;
            }
        }
    };

    const autofillAnswers = () => {
        const updatedAnswers = {};

        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((question) => {
                if (question.type === 'ChoiceQuestion') {
                    if (question.choiceQuestion?.choices.length > 0) {
                        const firstChoiceId = question.choiceQuestion.choices[1].id;
                        updatedAnswers[question.id] = { "answerValues": [firstChoiceId], "profileVisible": false };
                    }
                }
                if (question.type === 'TextQuestion' || question.type === 'NumberQuestion') {
                    if (question.id == 179) {
                        updatedAnswers[question.id] = { "answerValues": [123], "profileVisible": false };
                    } else updatedAnswers[question.id] = { "answerValues": [], "profileVisible": false };
                    if (question?.textQuestion?.type == 'RichTextArea' && (question.id == 172 || question.id == 165)) {
                        const freeTextContent = "<p>With Gleichklang you can now take the decisive step on the way to a happy partnership or a real friendship After registration you can view and fill in all questionnaires and view your results. This is not binding and you are not obliged to decide to use the paid placement service.This is not binding and you are not obliged to decide to use the paid placement service.<p>";
                        const initialEditorState =
                            EditorState.createWithContent(ContentState.createFromBlockArray(
                                convertFromHTML(freeTextContent).contentBlocks,
                                convertFromHTML(freeTextContent).entityMap
                            ))

                        setEditorStates(prevEditorStates => ({
                            ...prevEditorStates,
                            [question.id]: initialEditorState
                        }));
                        updatedAnswers[question.id] = { "answerValues": [freeTextContent], "profileVisible": false };
                    }
                }
            });
        });
        setAnswers(updatedAnswers);
        setErrResponse({})
        validateMulRangeResubmit()
    };

    const questionReference = (question, evt) => {
        if (evt) {
            questionRefs.current.set(question.id.toString(), evt);
        } else {
            questionRefs.current.delete(question.id.toString());
        }
    }

    const autoRegister = () => {
        completeRegistration(accessToken).then((result) => {
            if (result?.status === 200) {
                props.setQuestionaireId(result.data?.nextStep);
            } else {
                setErrResponse(result.data);
            }
        })
    }


    let questionnaireHeader;
    if (props.questionaire?.id == props.allQuestionairIds?.partner?.key) { //Partnership 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
            <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.partnerschaft?.key) { //Partnership 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.['partner.region_question']?.key) { //Partnership 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
            <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.freund?.key) { //Friendship 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
            <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.freundschaft?.key) { //Friendship 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.['friend.region_question']?.key) { //Friendship 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
            <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.kontaktliste?.key) { //Personal Details 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step1of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.person?.key) { //Personal Details 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step2of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.aussehen?.key) { //Personal Details 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step3of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.persoenlichkeit?.key) { //Personal Details 4
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step4of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.hobby_v?.key) { //Personal Details 5
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step5of5')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.gesellschaft?.key) { //Social Engagement 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.socialEng')}</strong> &ndash; {t('member.preregistration.step1of1')}</h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.ptext?.key) { //Self description partnership
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourPartnershipProfile')}</strong></h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.ftext?.key) { //Self description friendship
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourFriendshipProfile')}</strong></h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.registration?.key) { //Member Survey
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.helpUs')}</strong></h3>
            <h4>{props.questionaire?.name}</h4></div>;
    } else {
        questionnaireHeader = <h4>{props.questionaire?.name}</h4>;
    }

    return (
        <div className={"questionnaire_form questionnaire" + props.questionaire?.id}>
            <div className="looking_for">
                {questionnaireHeader}
                <p dangerouslySetInnerHTML={{ __html: props.questionaire?.description }}></p>
            </div>
            {process.env.REACT_APP_ENV != 'PROD' ? <div className="mt-3 mb-3 auto_fill_wrapper">
                {(props.questionaire?.id == 14 || props.questionaire?.id == 4) ? null :
                    <Button
                        className='mx-5'
                        variant="secondary"
                        onClick={() => autoRegister()}
                    >
                        {t('registration.buttons.autoregister')}
                    </Button>
                }
                {props.questionaire?.id == 12 ||
                    props.questionaire?.id == 13 ? null : <Button
                        className='mx-5'
                        variant="success"
                        onClick={autofillAnswers}
                    >
                    {t('registration.buttons.autofill')}
                </Button>}
                <Button
                    className='mx-5'
                    variant="primary"
                    onClick={() => forQuetionaireAnswer()}
                >
                    {t('registration.buttons.next')}
                </Button>
            </div> : null}
            <div className='from_control questionair_container' id='questionnaire_container' ref={scrollRef}>
                {props.questionaire?.questionGroups?.map((qgroup) => (
                    <div key={qgroup.name}>
                        <div className='questionair_header'>
                            <h3 className='questionair_heading'>{qgroup.name}</h3>
                        </div>
                        <div className="questions_wrapper">
                            <p className="group_description" dangerouslySetInnerHTML={{ __html: qgroup.description }}></p>
                            {
                                qgroup.questions.map((data, i) => {

                                    if (data.type === 'ChoiceQuestion') {
                                        return (
                                            <ChoiceQuestions
                                                key={`ChoiceQuestion-${data.id || i}`}
                                                data={data}
                                                questionReference={questionReference}
                                                errResponse={errResponse}
                                                answers={answers}
                                                setAnswers={setAnswers}
                                                onAlertOpen={onAlertOpen}
                                                qgroup={qgroup}
                                                handleOnChange={handleOnChange}
                                                handleOptionClick={handleOptionClick}
                                                questionaire={props?.questionaire}
                                                setStoreQuestionaire={props.setStoreQuestionaire}
                                            />
                                        )
                                    }

                                    if (data.type === 'NumberQuestion')
                                        return (
                                            <NumberQuestions
                                                key={`NumberQuestion-${data.id || i}`}
                                                data={data}
                                                questionReference={questionReference}
                                                errResponse={errResponse}
                                                answers={answers}
                                                setAnswers={setAnswers}
                                                onAlertOpen={onAlertOpen}
                                                qgroup={qgroup}
                                                handleOptionClick={handleOptionClick}
                                                updateErrorState={updateErrorState}
                                                handleOnChange={handleOnChange}
                                                clearErrorState={clearErrorState}
                                                errorMgsByID={errorMgsByID}
                                                validateMultipleRanges={validateMultipleRanges}
                                            />
                                        )

                                    if (data.type === 'TextQuestion')
                                        return (
                                            <TextQuestions
                                                key={`TextQuestion-${data.id || i}`}
                                                data={data}
                                                questionReference={questionReference}
                                                errResponse={errResponse}
                                                answers={answers}
                                                setAnswers={setAnswers}
                                                onAlertOpen={onAlertOpen}
                                                qgroup={qgroup}
                                                handleOptionClick={handleOptionClick}
                                                updateErrorState={updateErrorState}
                                                handleOnChange={handleOnChange}
                                                clearErrorState={clearErrorState}
                                                errorMgsByID={errorMgsByID}
                                                validateMultipleRanges={validateMultipleRanges}
                                                editorStates={editorStates}
                                                setEditorStates={setEditorStates}
                                                freeTextError={freeTextError}
                                                freeTextId={freeTextId}
                                                numberOfChildren={numberOfChildren}
                                                setSelectedChildrenAge={setSelectedChildrenAge}
                                                selectedChildrenAge={selectedChildrenAge}
                                            />
                                        )

                                    if (data.type === 'RegionQuestion')
                                        return (
                                            <RegionalSearch
                                                key={`region-${data.id}`}
                                                data={data}
                                                questionaireId={props.questionaire?.id}
                                                setAnswers={setAnswers}
                                                isFromRegistration={true}
                                            />
                                        )
                                })
                            }
                        </div>
                    </div>

                ))}
                <div className="mt-3 step_one_next">
                    <Button
                        className='mx-5'
                        variant="primary"
                        onClick={() => forQuetionaireAnswer()}
                    >
                        {t('registration.buttons.next')}
                    </Button>
                </div>
            </div>
            {isIAlertOpen ?
                <DescriptionAlert
                    description={idescription}
                    show={isIAlertOpen}
                    handleClose={handleClose}
                /> : null}
        </div>
    )
}

export default Questionaire;