
// import React from "react";
// import "./index.css";
// import App from "./App";
// import { render } from "react-dom";    

// /*** this change made resolved editor formating and dropdown issue ***/ 

// // const root = ReactDOM.createRoot(document.getElementById("root"));
// // root.render(

// render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

/****  ReactDOM.render is no longer supported in React 18.
//  Use createRoot instead. Until you switch to the new API, your app will behave as if it’s running React 17. Learn more:
//  https://reactjs.org/link/switch-to-createroot  ****/

import { createRoot } from 'react-dom/client';
import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
