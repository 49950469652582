import React, { useEffect, useContext, useState } from 'react'
import GlkHeader from '../../components/Header/GlkHeader';
import membershipIcon from '../../assets/images/gallery.png';
import UserIcon from '../../assets/images/user-icon01.png';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner';
import {
  getGallery,
  getImage,
  addGallery,
  RenameOrDeleteGallery
} from './personalDetailsService';
import { LoginContext } from '../../context/loginContext';
import './photogallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import heic2any from 'heic2any';
import {
  faArrowLeft,
  faTrashCan,
  faPen,
  faShareFromSquare,
  faCopy,
  faUserFriends,
  faHeart,
  faUsersSlash,
  faCheck, 
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import ShareGalleryView from './shareGalleryView';
import sharedToSuggestionIcon from '../../assets/images/sharedToSuggestionIcon.png';
import {
  Button,
  Form,
  Alert,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import ImagesGallery from './imagesGallery';
import Spinner from '../../components/spinner';

function GalleriesList() {
  const [galleryData, setGalleryData] = useState();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isGalleryLoading, setIsGalleryLoading] = useState(false);
  const [isGalleryRefreshed, setIsGalleryRefreshed] = useState(false);
  const [currentGallery, setCurrentGallery] = useState();
  const [isGalleryNameEdit, setIsGalleryNameEdit] = useState(false);
  const [renameGallery, setRenameGallery] = useState();
  const [type, setType] = useState(null);
  const [show, setShow] = useState(false);
  const [isMaxGalleryReached, setIsMaxGalleryReached] = useState(false);
  const [isGalleryNamelengthValid, setIsGalleryNamelengthValid] = useState(true);
  const [isImageAddedInGallery, setIsImageAddedInGallery] = useState(false);
  const [isDuplicateGalleryName,setIsDuplicateGalleryName]=useState()
  const [duplicateGalleryName,setDuplicateGalleryName]=useState(false)
  const [isErrorForAddGallery, setIsErrorForAddGallery] = useState(false);
  const [errorMsgForAddGallery, setErrorMsgForAddGallery] = useState();  
  const [selectedImages, setSelectedImages] = useState([]);

  const { t, i18n } = useTranslation();
  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  useEffect(() => {
    const data = {
      accessToken: accessToken,
      language: i18n.language
    }
    getGallery(data).then((response) => {
      if (response?.status === 200) {
        setGalleryData(response?.data.galleries);
        setIsGalleryNameEdit(false);
        if (response?.data.galleries?.length == 10) {
          setIsMaxGalleryReached(true);
        }else{
          setIsMaxGalleryReached(false);
        }
      }
    })
  }, [isGalleryRefreshed, isImageAddedInGallery])

  const data = {
    "image": membershipIcon,
    "menuName": t('member.personaldata.personaldetails.title'),
    "header": t('member.gallery.photogalleries'),
    "description": t('member.gallery.description'),
    "color": "#464565"
  }

  const onGalleryClick = (gallery) => {
    setGalleryImages([]);
    setSelectedImages([]);
    setCurrentGallery(gallery);
    setIsGalleryLoading(true);
    setIsMaxGalleryReached(false);
    const images = gallery?.mediaDTOS;
    if (images?.length) {
      images.forEach(async (image) => {
        try {
          const imageUrl = await onGetImage(image.id);
          setGalleryImages((prevImages) => [
            ...prevImages,
            { image, imageUrl },
          ]);

          setIsGalleryLoading(false);
        } catch (error) {
          setGalleryImages((prevImages) => [
            ...prevImages,
            { image,  },
          ]);
          console.error(`Error fetching image for ID ${image.id}:`, error);
        }
      });
    } else setIsGalleryLoading(false);
    setIsGalleryOpen(true);
    setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  };

  const onGetImage = (imageId) => {
    return new Promise((resolve, reject) => {
      const data = {
        accessToken: accessToken,
        language: i18n.language,
        id: imageId
      };

      getImage(data).then(async (response) => {
        if (response.status === 200) {
          const blob = await response.blob();
          let fileURL;
          if (blob.type == 'image/heif') {
            const jpegBlob = await heic2any({
              blob: blob,
              toType: 'image/jpeg',
              quality: 1
            });
            fileURL = URL.createObjectURL(jpegBlob);
          } else {
            fileURL = URL.createObjectURL(blob);
          }
          resolve(fileURL);
        } else {
          reject(new Error("Failed to get image."));
        }
      }).catch(error => {
        reject(error);
      });
    });
  };

  const onBack = () => {
    setIsGalleryLoading(false);
    setIsGalleryOpen(false)
    setCurrentGallery()
  }

  const compareGalleryName = (name) => {
    let changedName = name;
    for (let i = 1; galleryData?.find(item => item.name == changedName); i++) {
      changedName = name + i;
    }
    return changedName;
  }

const addNewGallery = (id) => {
    const galleryName = id ?
      {
        "newGalleryName": compareGalleryName(t('member.gallery.newgallery')),
        "sourceGalleryId": id
      } :
      { "newGalleryName": compareGalleryName(t('member.gallery.newgallery'))};

    const data = {
      accessToken: accessToken,
      language: i18n.language,
      newGalleryName: galleryName
    }

    if (galleryData?.length == 10) {
        setIsMaxGalleryReached(true);
    } else {
      addGallery(data).then((result) => {
        if (result?.status === 200) {
          setIsGalleryRefreshed(!isGalleryRefreshed)
        }
        else {
          setIsErrorForAddGallery(true);
          setErrorMsgForAddGallery(result?.data?.message);
        }
      })
    }
  }

  const galleryDelete = (data) => {
    setShow(true);
    setType('delete');
    setCurrentGallery(data);
  }

  const galleryRename = (data) => {
    setDuplicateGalleryName(false)
    setIsDuplicateGalleryName()
    setIsGalleryNameEdit(true);
    setCurrentGallery(data);
    setRenameGallery(data?.name.length > 20 ? data?.name.substring(0, 20) : data?.name)
  }

  const shareGallery = (data) => {
    setShow(true);
    setType('share');
    setCurrentGallery(data);
  }

  const onGalleryRenameOrDelete = (gallery, isDelete) => {
    let NameLength = renameGallery?.length;
    let newName;
    if (NameLength > 20) {
      newName = renameGallery?.slice(0, 20);
      setIsGalleryNamelengthValid(false);
    }
    else {
      newName = renameGallery;
      setIsGalleryNamelengthValid(true);
    }
    const galleryName = isDelete ? gallery.name : newName
    const data = {
      "id": gallery.id,
      "name": galleryName,
      "delete": isDelete
    }
    const body = {
      accessToken: accessToken,
      language: i18n.language,
      body: data
    }

    RenameOrDeleteGallery(body).then((result) => {
      if (result?.status === 200) {
        setIsGalleryRefreshed(!isGalleryRefreshed);
        setShow(false);
        setIsDuplicateGalleryName()
      }
      else {
        setIsDuplicateGalleryName(result.data.message);
        setDuplicateGalleryName(true)
      }      
    })
  }

  const updateGalleryShare = (galleryShare) => {
    setGalleryData((prev) => 
      prev.map((item) => item.id === galleryShare.id ? {
        ...item,
        allPartnership: galleryShare.allPartnership,
        partnershipMarkedInteresting: galleryShare.allPartnership === true ? false : galleryShare.partnershipMarkedInteresting,
        allFriendship: galleryShare.allFriendship,
        friendshipMarkedInteresting: galleryShare.allFriendship === true ? false : galleryShare.friendshipMarkedInteresting,
        customSelection: galleryShare.customSelection
      } : item)
    );
  }

  /*remove error after 5 second*/
  useEffect(() =>{
    setTimeout(() => {
      setIsGalleryNamelengthValid(true);      
    }, 5000);
  }, [isGalleryNamelengthValid])

  const base64Image = (image) =>{
    return `data:image/jpeg;base64,${image}`
  }  

  return (
    <div>
			<GlkHeader/>
			<main className="main_wrapper personal_details_wrapper">
      <Banner data={data} />

      <div className='photo_gallery'>
        <div className="status_myprofile">
          <h3>
							{currentGallery ? 
              <Button
                onClick={() => onBack()}
                className='back_button'
                variant="outline-danger" 
                aria-label={t('suggestions.back')}
              >
                <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
              </Button> : ''} 
              
              {currentGallery ? `${t('member.gallery.gallery')} - ${currentGallery?.name.length > 20 ? currentGallery?.name?.substring(0, 20) : currentGallery?.name}` : `${t('member.gallery.mygalleries')}`}</h3>
        </div>
      {galleryData ? (
        <div>

        <div className='galleries_container'>
          {!isGalleryOpen
            ? galleryData?.map((gallery, index) =>
              <div key={gallery.id} className='g_container'>
                <div className='galleries'>
                  <div className='category_share'>
                    <div className='share_col'>
                      <strong>{t('member.audio.sharedwith')}:</strong>
                      {gallery?.allPartnership ? 
                        <span className="share_icon share_partnership" title={t('member.gallery.proposal')}><FontAwesomeIcon icon={faHeart} /> {t('member.menubar.partnership')}</span> : null}
                      {gallery?.allFriendship ? 
                        <span className="share_icon share_friendship" title={t('member.gallery.suggestions')}><FontAwesomeIcon icon={faUserFriends} /> {t('member.menubar.friendship')}</span> : null}
                      {!gallery?.allPartnership && !gallery?.allFriendship &&
                        !gallery?.partnershipMarkedInteresting && !gallery?.friendshipMarkedInteresting && !gallery?.customSelection
                        ? <span className="share_icon share_none"><FontAwesomeIcon icon={faUsersSlash} /> {t('member.gallery.nobody')}</span> : null}
                      {gallery?.customSelection === true 
                        ? <span className="share_icon share_selected" title={t('member.gallery.selected')}><FontAwesomeIcon icon={faUserPlus} /> {t('member.gallery.selected')}</span> : null}
                         {gallery?.partnershipMarkedInteresting ? 
                          <span className="share_icon share_partnership" title={t('member.gallery.allpartnership')}><FontAwesomeIcon icon={faHeart} /> <FontAwesomeIcon icon={faCheck} /> {t('member.menubar.partnership')}</span> : null}
                         {gallery?.friendshipMarkedInteresting ? 
                          <span className="share_icon share_friendship" title={t('member.gallery.allfriends')}><FontAwesomeIcon icon={faUserFriends} /> <FontAwesomeIcon icon={faCheck} /> {t('member.menubar.friendship')}</span> : null}
                    </div>
                  </div>
                  {gallery.mediaDTOS && gallery.mediaDTOS.length == 0 ?
                   <button 
                   onClick={() => { onGalleryClick(gallery) }} 
                   className='no_image'>
                   {t('member.gallery.noImage')}
                 </button>                     
                      : null}
                  {gallery.mediaDTOS && gallery.mediaDTOS.length > 4 ?
                      <div
                        onClick={() => onGalleryClick(gallery)}
                        className='image_wrapper'>
                        <div className='single_image'><img className='images' src={gallery?.thumbnails?.[0] && base64Image (gallery?.thumbnails?.[0].thumbnail)} alt="Gallery thumbnail 1" /></div>
                        <div className='single_image'><img className='images' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt="Gallery thumbnail 2" /></div>
                        <div className='single_image'><img className='images' src={gallery?.thumbnails?.[2] &&base64Image(gallery?.thumbnails?.[2].thumbnail)} alt="Gallery thumbnail 3" /></div>
                        <div className='single_image'><img id={gallery.mediaDTOS.length > 4 ? 'image4' : ''} className='images' src={gallery?.thumbnails?.[3] && gallery?.thumbnails?.[3].thumbnail ? base64Image(gallery?.thumbnails?.[3].thumbnail): UserIcon} 
                         alt={gallery?.thumbnails?.[3] ? "Gallery thumbnail 4" : "Default user icon"} 
                        />
                        {gallery.mediaDTOS.length > 4 ? <span className="plus_p">+{gallery.mediaDTOS && gallery.mediaDTOS.length - 4}</span> : null}</div>
                      </div>
                      : null                      
                  }
                  {gallery.mediaDTOS && gallery.mediaDTOS.length == 1 ?
                    <div
                      onClick={() => onGalleryClick(gallery)}
                      className='galleryWith1img'>
                      <img className='gallery' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)}/>
                      <div className="img1plus">+</div>
                    </div>
                    : null
                  }
                  {gallery.mediaDTOS && gallery.mediaDTOS.length == 2 ?
                    <div
                      onClick={() => onGalleryClick(gallery)}
                      className='galleryWith2img'>                        
                      <div>
                        <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)}  />
                        <img className='' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)}  />
                      </div>
                      <div>
                        <div className="img1plus">+</div>
                      </div>
                    </div>
                    :
                    null
                  }
                  {gallery.mediaDTOS && gallery.mediaDTOS.length == 3 ?
                    <div
                      onClick={() => onGalleryClick(gallery)}
                      className='galleryWith3img'>
                      <div>
                        <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail) } alt="Gallery thumbnail 5"/>
                        <img className='' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt="Gallery thumbnail 6"/>
                      </div>
                      <div>
                        <img className='' src={gallery?.thumbnails?.[2] && base64Image(gallery?.thumbnails?.[2].thumbnail)}  alt="Gallery thumbnail 7"/>
                        <div className="img3plus">+</div>
                      </div>

                    </div>
                    :
                    null}
                  {gallery.mediaDTOS && gallery.mediaDTOS.length == 4 ?
                    <div
                      onClick={() => onGalleryClick(gallery)}
                      className='galleryWith4img'>                        
                      <div>
                        <img className='' src={gallery?.thumbnails?.[0] && base64Image(gallery?.thumbnails?.[0].thumbnail)} alt="galleryWith6img"/>
                        <img className='' src={gallery?.thumbnails?.[1] && base64Image(gallery?.thumbnails?.[1].thumbnail)} alt="galleryWith7img"/>
                      </div>
                      <div>
                        <img className='' src={gallery?.thumbnails?.[2] && base64Image(gallery?.thumbnails?.[2].thumbnail)} alt="galleryWith8img"/>
                        <img className='' src={gallery?.thumbnails?.[3] && base64Image(gallery?.thumbnails?.[3].thumbnail)} alt="galleryWith5img"/>
                      </div>
                    </div>
                    :
                    null}
                  <div className='category_icons'>
                    <OverlayTrigger
                      key='edit'
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-edit`}>
                          {t('member.gallery.edit')}
                        </Tooltip>
                      }
                    >
                       <Button variant= '#f0f2e0' size="sm" onClick={() => galleryRename(gallery)} title="Rename Gallery" aria-label="Rename Gallery" 
                         style={{ border: 'none' }} 
                        >
                      <FontAwesomeIcon
                        icon={faPen}/>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key='share'
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-share`}>
                          {t('member.gallery.release')}
                        </Tooltip>
                      }
                    >
                      <Button variant= '#f0f2e0' size="sm" onClick={() => shareGallery(gallery)} aria-label="shareGallery"  style={{ border: 'none' }} >
                        <FontAwesomeIcon icon={faShareFromSquare} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key='Duplicate'
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-duplicate`}>
                          {t('member.gallery.duplicate')}
                        </Tooltip>
                      }
                    >
                        <Button variant= '#f0f2e0' size="md" onClick={() => addNewGallery(gallery.id)} aria-label='addNewGallery'  style={{ border: 'none' }} >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key='delete'
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-delete`}>
                          {t('member.gallery.delete')}
                        </Tooltip>
                      }
                    >
                       <Button variant= '#f0f2e0' size="md" onClick={() => galleryDelete(gallery)} aria-label='galleryDelete'  style={{ border: 'none' }} >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </Button>
                    </OverlayTrigger>         
                  </div>
                </div>
                <div className='g_name_container'>
                  {isGalleryNameEdit && !duplicateGalleryName && currentGallery?.id == gallery?.id ?
                    <Form.Group className="" controlId="formGridState">
                      <Form.Control
                        value={renameGallery}
                        id={gallery?.name}
                        aria-label={gallery?.name}
                        onChange={(e) => setRenameGallery(e.target.value)}
                        onBlur={() => onGalleryRenameOrDelete(gallery, false)}
                      />
                    </Form.Group>
                    :
                    <span className='galleryname'>{gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
                    </span>
                  }
                </div>
                <div className='valid_name'>
                  {!isGalleryNamelengthValid && currentGallery?.id == gallery?.id ? 
                  <Alert
                    className='member_address'
                    variant="danger"
                    id='main_alert'
                    onClose={() => setIsMaxGalleryReached(false)}
                    dismissible
                  >
                    <p>{t('member.gallery.validname')}</p>
                  </Alert> : null}
                  {isDuplicateGalleryName && currentGallery?.id == gallery?.id && (

                    <Alert
                      className='member_address'
                      variant="danger"
                      id='main_alert'
                      onClose={() => setIsMaxGalleryReached(false)}
                      dismissible

                    >
                      <p>{isDuplicateGalleryName}</p>
                    </Alert>
                  )}
                </div>
              </div>
            ) :
            <>{isGalleryLoading ? <p>{t('member.gallery.fetching')}</p> : null}</>
          }
          {isGalleryOpen ?
            <ImagesGallery
              galleryImages={galleryImages}
              currentGallery={currentGallery}
              isGalleryLoading={isGalleryLoading}
              setIsImageAddedInGallery={setIsImageAddedInGallery}
              isImageAddedInGallery={isImageAddedInGallery}
              onGalleryClick={onGalleryClick}
              setGalleryData={setGalleryData}
              galleryData={galleryData}
              setSelectedImages={setSelectedImages}
              selectedImages={selectedImages}
            />
            : null
          }
        </div>
        {isMaxGalleryReached && !isGalleryOpen ? <Alert
          className='member_address'
          variant="danger"
          id='main_alert'
          onClose={() => setIsMaxGalleryReached(false)}
          dismissible
        >
          <p>{t('member.gallery.maxgallery')}</p>
        </Alert> : null}
        {galleryData?.length == 0 ? <Alert
          className='member_address'
          variant="danger"
          id='main_alert'
          dismissible
        >
          <p>{t('member.gallery.nogallery')}</p>
        </Alert> : null}
        {isErrorForAddGallery ? <Alert
          className='member_address'
          variant="danger"
          id='main_alert'
          dismissible
          onClose={() => setIsErrorForAddGallery(false)}
        >
          <p>{errorMsgForAddGallery}</p>
        </Alert> : null}
        {!isGalleryLoading && isGalleryOpen ?
          <Form.Group className='list_btn' >
            <div>
              <Button
                onClick={() => onBack()}
                className='mb-3 mt-1'
                variant="outline-danger"
              >
                <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                {t('suggestions.back')}
              </Button>
            </div>
          </Form.Group> : null}
        {!isGalleryOpen && !isGalleryLoading && !galleryImages?.length > 0 && !isMaxGalleryReached ?
          <Form.Group className='list_btn add_g_btn' >
            <div>
              <Button
                onClick={() => addNewGallery()}
                className='mb-3 mt-1 '
                variant="primary"
              >
                {t('member.gallery.addgallery')}
              </Button>
            </div>
          </Form.Group> : null}
        </div>
          
        ):(
          <Spinner/>
        )}
      </div>
      <ShareGalleryView
        show={show}
        setShow={setShow}
        setCurrentGallery={setCurrentGallery}
        currentGalleryData={currentGallery}
        isGalleryRefreshed={isGalleryRefreshed}
        setIsGalleryRefreshed={setIsGalleryRefreshed}
        onGalleryDelete={onGalleryRenameOrDelete}
        updateGalleryShare={updateGalleryShare}
        type={type}
      />
		</main>
    </div>
  )
}

export default GalleriesList;