import React, { useEffect, useState } from 'react';
import './App.css';
import { LoginContext } from './context/loginContext.js';
import MainRoutes from './root.js';
import LoginReducer from './pages/login/loginReducer.js';
import { useReducer } from 'react';
import GlkFooter from './components/Footer/GlkFooter.js';
import { getMemberDetails } from './pages/login/loginService.js';

function App() {
  const [isTokenExpired, setIsTokenExpired] = useState(false);

let initialValues = {
  memberLogin : null,
  memberDetails :null,
  accessToken: null,
  currentUrl: null,
  isConfirmMailPopup: true,
  language: 'de'
}

  const [loginData, dispatch] = useReducer(
    LoginReducer,
    initialValues
  );  

  let accessToken = localStorage.getItem('accessToken')
  useEffect(() => {
    dispatch({ type: 'ACCESS_TOKEN', data: accessToken })
    if (accessToken) {      
      getMemberDetails(accessToken).then((response) => {
        if (response?.status == 200) {
          dispatch({ type: 'MEMBER_DETAILS', data: response.data })
        }
        else if(response?.status == 401) {
          localStorage.removeItem('accessToken');
          dispatch({ type: 'LOGIN', data: null });
          dispatch({ type: 'MEMBER_DETAILS', data: null });
          setIsTokenExpired(true)
        }
    })
  }
}, []);

  return (
    <LoginContext.Provider value={{ loginData, dispatch } }>
      <div className="App">        
        <MainRoutes isTokenExpired={isTokenExpired}/>
        {accessToken ? <GlkFooter /> : null}
      </div>
    </LoginContext.Provider>
  );
}

export default App;
