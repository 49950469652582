import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EnJson from './en.json';
import DeJson from './de.json';



const resources = {
    en: { translation: EnJson },
    de: { translation: DeJson }
};

let selectedLanguage = localStorage.getItem('language') || 'de';

i18n.use(initReactI18next).init({
    resources,
    lng: selectedLanguage,
    interpolation: {
        escapeValue: false,
    }
});

export default i18n;
