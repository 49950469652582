import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import imageLogo from '../../../assets/images/icon_profile_white-sugg1-svg.svg';
import Button from 'react-bootstrap/Button';
import { useState, useRef } from 'react';
import { useContext } from 'react';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import Cropper from './profileImageCropper';
import ViewAllGalleries from './viewAllGalleries';

function UploadImage(props) {
    const [uploadType, setUploadType] = useState(null);
    const [imageBeforeSave, setImageBeforeSave] = useState(null);
    const [profileImg, setProfileImg] = useState();
    const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false)
    const [isImageCrop, setIsImageCrop] = useState(false);
    const [showAllGalleries, setShowAllGalleries] = useState(false)
    
    let {
        loginData: {
          accessToken
        }, 
      } = useContext(LoginContext);
      const { t, i18n } = useTranslation();
    const inputImageSelect = useRef();


    const UploadFile = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const fileData = e.target.files[0];
            setProfileImg(fileData)
            setImageBeforeSave(URL.createObjectURL(e.target.files[0]));
        }
        setIsImageCrop(true)
    }

      const onImageSave = () => {
            setUploadType(props.type)
            inputImageSelect.current.click()
      }
    
    const openAllGalleriesPopup = async() => {
        setShowAllGalleries(true)
        setUploadType(props.type)
    }

    const closeAllGalleriesPopup = () => {
        setShowAllGalleries(false)
    }

    const base64Image = (image) => {
        return `data:image/png;base64,${image}`;
    };

    return (
        <div className="modal show">
            <Modal className={'incomplete_q_modal '+props.type} show={props.show} onHide={() => props.handleClose()} >
                <Modal.Header closeButton>
                    <div className="profile_logo_name">
                        <div className="profile_info">
                            <h3 className="partnership_color"><FontAwesomeIcon icon={faUser} /> Avatar</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='incomplete_questionaire'>
                    <div className="">
                        <div className="descriptions">
                            <p>{t('myprofile.avtardesc')}</p>
                            <p>{t('myprofile.category')}</p>
                        </div>
                        <h3 className="headings">
                            <img src={require("../../../assets/images/icon_category_partnership.png")} alt="Partnership Icon" className="categoryImage" /> 
                                {props.type == 'partnership' ? t('myprofile.partnership') : t('myprofile.friendship')}
                            </h3>
                    </div>
                {!isImageCrop ?(
                        <>
                    <div className='profile_image'>
                        {imageBeforeSave ?  <img src={imageBeforeSave} alt='myprofileImg'/> : <img src={props.avatarUrl? props.avatarUrl : imageLogo} alt='myprofileImg'/>}
                    </div>
                  
                    <div className="uploadimagebtn">
                        <Button 
                        disabled={isSaveBtnDisable}
                        onClick={() => onImageSave()}
                            variant="primary">
                            {profileImg ? t('myprofile.confirmsave') : t('myprofile.addimage') }
                        </Button>
                        <Button variant="success"
                        onClick={() => openAllGalleriesPopup()}>
                        {t('myprofile.fromgallery')}
                        </Button>                        
                        <div className='card_input'>
                            <input id="file-upload"
                                accept="image/*" 
                                ref={inputImageSelect}
                                name="audio-and-image-upload"
                                onChange={(e) => UploadFile(e)}
                                type="file"
                                aria-label="Uplaod File"
                            />
                        </div>
                    </div>
                    <>
                    <ViewAllGalleries
                        close={closeAllGalleriesPopup}
                        show={showAllGalleries}
                        base64Image={base64Image}
                        type={props.type}
                        setAvatar={props.setAvatar}
                        handleClose={props.handleClose}
                        setImageCrop={setIsImageCrop}
                        setImageBeforeSave={setImageBeforeSave}
                    />
                    </>
                        </>
                ):(
                    <Cropper
                        show={isImageCrop}
                        setShow={setIsImageCrop}
                        currentImage={imageBeforeSave}
                        setImages={setImageBeforeSave}
                        uploadType={uploadType}
                        setUploadType={setUploadType}
                        handleClose={props.handleClose}
                        setAvatar={props.setAvatar}
                        isAvatarHomePage={props.isAvatarHomePage}
                    />  
                )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UploadImage;