const paymentStatusURL = 'https://www.gleichklang.de/payrexx/api/getPaymentStatus/';

export async function getPaymentStatus(reference) {
	
	return fetch('https://www.gleichklang.de/payrexx/api/getPaymentStatus/?reference='+reference, {
		method: 'GET',
		'mode': 'cors',
	})
		.then((response) =>
			response.json().then((data) => ({
				data: data,
				status: response.status,
			}))
		)
		.then(async (responseData) => {
			return responseData;
		})
		.catch((err) => {
			throw err;
		});
		
}