import React from "react";
import Alert from 'react-bootstrap/Alert';

const AlertModal = ({ show, setShow, variant = 'danger', body, className='', isDismissible= false }) => {
	return (
		<Alert dismissible={isDismissible} className={`pre_registration_alert ${className && className}`} show={show} variant={variant} onClose={() => setShow(false)}>
			{body}
		</Alert>
	)
}

export default AlertModal;
