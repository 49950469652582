import React, { useEffect, useState } from 'react';
import GlkHeader from '../../../components/Header/GlkHeader';
import Banner from '../../../components/banner/banner';
import suggestionIcon from '../../../assets/images/contact_lists.png';
import beruf1 from '../../../assets/images/beruf1.jpg';
import nature1 from '../../../assets/images/nature1.jpg';
import project1 from '../../../assets/images/project1.jpg';
import treffen1 from '../../../assets/images/treffen1.jpg';
import { useTranslation } from 'react-i18next';
import CommunityLists from './communityLists';
import Button from 'react-bootstrap/Button';
import './communityContacts.css'

function CommunityContacts() {
  const [listName, setListName] = useState('contact-list');

  useEffect(() => {
    setListName('contact-list');
  }, [])

  const { t } = useTranslation();

  const handleNavigateNature = () => {
    setListName('nature');
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  }

  const handleNavigateProject = () => {
    setListName('project');
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  }

  const handleNavigateBeruf = () => {
    setListName('beruf');
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  }

  const handleNavigateTreffen = () => {
    setListName('treffen');
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
  }

  const dataNature = {
    "image": suggestionIcon,
    "menuName": t('suggestions.communityContacts'),
    "header": t('suggestions.nature'),
    "description": t('suggestions.NatureDesc'),
    "color": "#59751c"
  }
  const dataBeruf = {
    "image": suggestionIcon,
    "menuName": t('suggestions.communityContacts'),
    "header": t('suggestions.beruf'),
    "description": t('suggestions.berufDesc'),
    "color": "#464565"
  }
  const dataProject = {
    "image": suggestionIcon,
    "menuName": t('suggestions.communityContacts'),
    "header": t('suggestions.project'),
    "description": t('suggestions.projectDesc'),
    "color": "#16738b"
  }
  const dataTreffen = {
    "image": suggestionIcon,
    "menuName": t('suggestions.communityContacts'),
    "header": t('suggestions.treffen'),
    "description": t('suggestions.treffenDesc'),
    "color": "#a32424"
  }

  const datacommunity = {
    "image": suggestionIcon,
    "menuName": t('suggestions.mysuggestion'),
    "header": t('suggestions.communityContacts'),
    "description": t('suggestions.communityDesc'),
    "color": "#59751c"
  }

  let data;

  switch (listName) {
    case 'nature':
      data = dataNature;
      break;
    case 'project':
      data = dataProject;
      break;
    case 'beruf':
      data = dataBeruf;
      break;
    case 'treffen':
      data = dataTreffen;
      break;
    case 'contact-list':
      data = datacommunity;
      break;
    default:
      data = null;
  }

  return (
    <div>
    <GlkHeader/>
      {listName == 'contact-list' ?
        <main className="main_wrapper suggestions_wrapper nature">
          <Banner data={data} />
          <div className='questionaire_in_menubar'>
            <div className='cummunity_contacts_container'>
              <div className="nature_box common_pro">
                <div className="image">
                  <img alt="" src={nature1} />
                </div>
                <div className="nature_content">
                  <div >
                    <p type="button"
                      onClick={() => handleNavigateNature()}
                      className="nature_btn"
                      tabindex="0"
                    >{t('contactlist.nature.heading')}</p>
                  </div>
                  <p>{t('contactlist.nature.description')}</p>                  
                  <Button
                    onClick={() => handleNavigateNature()}
                    className='contact_button' 
                  >
                    Show contact list
                  </Button>
                </div>
              </div>
              <div className="project_box common_pro">
                <div className="image">
                  <img alt="" src={project1} />
                </div>
                <div className="nature_content">
                  <div
                    onClick={() => handleNavigateProject()}
                    role="button">
                    <p className="nature_btn" tabindex="0">
                      {t('contactlist.project.heading')}</p>
                  </div>
                  <p>{t('contactlist.project.description')}</p>            
                  <Button
                    onClick={() => handleNavigateProject()}
                    className='contact_button' 
                  >
                    Show contact list
                  </Button>
                </div>
              </div>
              <div className="beruf_box common_pro">
                <div className="image">
                  <img alt="" src={beruf1} />
                </div>
                <div className="nature_content">
                  <div
                    onClick={() => handleNavigateBeruf()}
                    role="button">
                    <p className="nature_btn" tabindex="0">{t('contactlist.beruf.heading')}</p>
                  </div>
                  <p>{t('contactlist.beruf.description')}</p>              
                  <Button
                    onClick={() => handleNavigateBeruf()}
                    className='contact_button' 
                  >
                    Show contact list
                  </Button>
                </div>
              </div>
              <div className="treffen_box common_pro">
                <div className="image">
                  <img alt="" src={treffen1} />
                </div>
                <div className="nature_content">
                  <div
                    onClick={() => handleNavigateTreffen()}
                    role="button">
                    <p className="nature_btn" tabindex="0">{t('contactlist.treffen.heading')}</p>
                  </div>
                  <p>{t('contactlist.beruf.description')}</p>              
                  <Button
                    onClick={() => handleNavigateTreffen()}
                    className='contact_button' 
                  >
                    Show contact list
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
        :
        <div className={data.header}>
          <CommunityLists
            listName={listName}
            data={data}
            setListName={setListName}
          />
        </div>
      }
    </div>
  )
}

export default CommunityContacts;