import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import Spinner from '../../components/spinner';

function DeleteSuggestions(props) {
    const { changeRelationship, handleClose, show, affiliationType } = props;
    const { t } = useTranslation();
    const [showLoader, setShowLoader] = useState(false)
    function deleteSuggestion() {
        changeRelationship(affiliationType, false, true);
        setShowLoader(true);
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <ModalBody>
            {t('suggestions.deleteMsg')}
            </ModalBody>
            <Modal.Footer>
                    <Button variant="outline-danger" onClick={deleteSuggestion} >
                        {t('registration.buttons.proceed')}
                    </Button>
                <Button variant="secondary" onClick={handleClose}>
                    {t('common.close')}
                </Button>
                {showLoader ? <Spinner className="file_loader" /> : null}
            </Modal.Footer>
        </Modal>
    );
}

 export default DeleteSuggestions;
