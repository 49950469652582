import React, { useEffect, useContext, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import userIcon from '../../../assets/images/user-icon01.png';
import { getAvatar, getAudio, getProfileQustions, getAllGalleriesForMyprofile, getImage } from '../personalDetailsService';
import { getSuggestionsGallery } from '../../suggestions/suggestionService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import './myprofile.css';
import EditFreeText from './editFreeText';
import EditStatus from './editStatus';
import Loader from '../../../components/spinner';
import { Spinner } from 'react-bootstrap';
import Player from '../../../components/Audioplayer/Player';
import UploadImage from './uploadImage';
import Accordion from 'react-bootstrap/Accordion';
import ProfileQuestions from './profileQuestions';
import UserIcon from '../../../assets/images/user-icon.png';
import heic2any from 'heic2any';
import GalleryImages from '../../suggestions/suggestionsGalleryImages';
import Toast from 'react-bootstrap/Toast';

function Myprofile(props) {
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false)
	const [avatar, setAvatar] = useState(null)
	const [audio, setAudio] = useState(null)
	const [isUploadImgeOpen, setIsUploadImgeOpen] = useState(false)
	const [profileQ, setProfileQ] = useState()
	const [isProfileQuestionOpen, setIsProfileQuestionOpen] = useState(false);
	const [storeProfileQ, setStoreProfileQ] = useState();
	const [refreshProfileData, setRefreshProfileData] = useState(false);
	const [gallery, setgalllery] = useState();
	const [myProfileGalleryImages, setMyProfileGalleryImages] = useState([]);
    const [isGalleryLoading, setIsGalleryLoading] = useState(false);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const [isGalleryAccordianOpen, setIsGalleryAccordianOpen] = useState(true);
	const [isGalleryResponse, setIsGalleryResponse] = useState(false);
	const [showFreeTextError, setShowFreeTextError] = useState({ errorMsg: '', isError: false, statusCode: '', statusError:'' });


	const { profileData } = props;
	let {
		loginData: {
			accessToken,
			memberDetails
		},
	} = useContext(LoginContext);
	const { t, i18n } = useTranslation();
	const currentLang = i18n.language;
	const token_lang = {
		language: currentLang,
		accessToken
	}

	const openFreeTextPopup = () => {
		setIsPopupOpen(true)
	}

	const closeFreeTextPopup = () => {
		setIsPopupOpen(false)
	}

	const openStatusPopup = () => {
		setIsStatusPopupOpen(true)
	}

	const closeStatusPopup = () => {
		setIsStatusPopupOpen(false)
	}

	useEffect(() => {
		if (props?.profileData?.avatar && props.profileData.avatar !== null && (!avatar || avatar === null)) {
			const data = {
				accessToken: accessToken,
				language: currentLang,
				avatarId: props.profileData.avatar
			}
			getAvatar(data).then((response) => {
				processImage(response)
			})
		}
	}, [props.profileData])

	const processImage = async (response) => {
		if (response.status === 200) {
			const blob = await response.blob();
			const fileURL = URL.createObjectURL(blob);
			setAvatar(fileURL)
		}
	}

	useEffect(() => {
		if (props?.profileData?.audio && props.profileData.audio !== null && (!audio || audio === null)) {
			const data = {
				accessToken: accessToken,
				language: currentLang,
				audioId: props.profileData.audio
			}
			getAudio(data).then((response) => {
				processAudio(response)
			})
		}
	}, [props.profileData])

	const processAudio = async (response) => {
		if (response.status === 200) {
			const blob = await response.blob();
			const fileURL = URL.createObjectURL(blob);
			setAudio(fileURL)
		}
	}

	const openUploadImagePopup = () => {
		setIsUploadImgeOpen(true)
	}

	const closeUploadImagePopup = () => {
		setIsUploadImgeOpen(false)
	}

	useEffect(() => {
		if (props) {
			getProfileQustions(token_lang, props.type).then((result) => {
				if (result.status === 200) {
					setProfileQ(result?.data);
					setRefreshProfileData(false);
				}
			})
		}
	}, [refreshProfileData, currentLang])

	const onQuestionsOpen = (data, id, desc) => {
		const profileToBeOpenData = {
			data,
			id: id,
			description: desc
		}
		setStoreProfileQ(profileToBeOpenData)
		setIsProfileQuestionOpen(true)
	}

	const onQuestionsClose = () => {
		setIsProfileQuestionOpen(false)
	}

	const galleryForMyprofile = (category) => {
		setIsGalleryAccordianOpen(false);
		if (props && !gallery) {
			setIsGalleryResponse(true)
			getAllGalleriesForMyprofile(token_lang, category.toUpperCase()).then((result) => {
				if (result.status === 200) {
					setgalllery(result?.data)
					setIsGalleryResponse(false)
				}
			})
		}
	}

	const base64Image = (image) => {
		return `data:image/png;base64,${image}`;
	};

	const openGallery = (gallery) => {
		setMyProfileGalleryImages([]);
		setIsGalleryLoading(true);
		const images = gallery?.mediaDTOS;
		if (images.length) {
			images.forEach(async (image) => {
				try {
					const imageUrl = await onGetImage(image.id);
					setMyProfileGalleryImages((prevImages) => [
						...prevImages,
						{ image, imageUrl, galleryName: gallery.name },
					]);
					setIsGalleryLoading(false);
				} catch (error) {
					console.error(`Error fetching image for ID ${image.id}:`, error);
				}
			});
		} else setIsGalleryLoading(false);
		setIsGalleryOpen(true);
	};

	const onGetImage = (imageId) => {
		const data = {
			accessToken: accessToken,
			language: i18n.language,
			id: imageId
		};

		return new Promise((resolve, reject) => {
			getImage(data).then(async (response) => {
				if (response.status === 200) {
					const blob = await response.blob();
					let fileURL;
					if (blob.type == 'image/heif') {
						const jpegBlob = await heic2any({
							blob: blob,
							toType: 'image/jpeg',
							quality: 1
						});
						fileURL = URL.createObjectURL(jpegBlob);
					} else {
						fileURL = URL.createObjectURL(blob);
					}
					resolve(fileURL);
				} else {
					reject(new Error("Failed to get image."));
				}
			}).catch(error => {
				reject(error);
			});
		});
	};

	const closeImagePopup = () => {
		setIsGalleryOpen(false);
	}

	const sendCallOnlyAccordianOpen = () => {
		if (isGalleryAccordianOpen) {
			galleryForMyprofile(props.type)
		} else {
			setIsGalleryAccordianOpen(true)
		}
	}

	return (
		<>
			{profileData != null ?
				<div className="questionaire_in_menubar profile_wrapper">
					<div className="myprofile_header">
						<div className="visual_audio_wrapper">
							<div className="myprofile_image">
								<div className="user_img white_bg">
									<img src={avatar && avatar !== null ? avatar : userIcon} alt={"Avatar" + memberDetails?.alias} />
								</div>
								<button aria-label={t('myprofile.changeProfileImage')} onClick={() => openUploadImagePopup()} className='edit_image'>
									<FontAwesomeIcon icon={faPen} /> {t('myprofile.changeProfileImage')}
								</button>
							</div>
							{audio && audio !== null ?
								<div className="myprofile_audio">
									<strong className="myprofile_label">{t('myprofile.audio')}</strong>
									<Player
										audioUrl={audio}
									/>
								</div> : null}
						</div>
						<div className="text_wrapper">
							<div className="myprofile_info">							
								<Toast className='toaster_freetext' bg={'danger'} onClose={() => setShowFreeTextError({ errorMsg: '', isError: false , statusCode: ''})} show={showFreeTextError?.isError} delay={10000} autohide>
									<Toast.Header>
										<strong className="me-auto">Status Code: {showFreeTextError?.statusCode}</strong>
									</Toast.Header>
									<Toast.Body className={'text-white'}>
										<p>{showFreeTextError?.statusError || ''}
											{profileData?.freeTextQuestionnaire?.questionGroups?.map((data) => (
												<>
													{data?.questions.map((items) => (
														<div key={items.id}>
															{showFreeTextError?.errorMsg?.errorMessageByQuestionId?.[items.id] ? (
																<p>{showFreeTextError?.errorMsg?.errorMessageByQuestionId[items.id]}</p>
															) : null}
														</div>
													))}
												</>
											))}
										</p>
									</Toast.Body>
								</Toast>
								<strong className="myprofile_label">{t('myprofile.alias')}</strong>
								<h3 className="partnership_color">{memberDetails?.alias}</h3>
							</div>
							<div className="myprofile_status">
								<strong className="myprofile_label">{t('myprofile.status')}</strong>
								<button aria-label={t('myprofile.editStatus')} onClick={() => openStatusPopup()} className='edit_status'>
									<FontAwesomeIcon icon={faPen} />
								</button>
								<p className="status_description">{profileData?.status}</p>
							</div>
							<div className="myprofile_text">
								<div className="free_text_bar">
									<span className="freetext">{t('myprofile.freetext')}</span>
									<button aria-label={t('myprofile.editFreeText')} onClick={() => openFreeTextPopup()} className='edit_status'>
										<FontAwesomeIcon icon={faPen} />
									</button>
									{profileData?.freeTextQuestionnaire?.questionGroups.map((items, index) => (
										index > 0 ?
											items.questions[0]?.choiceQuestion?.selectedChoices[0]?.value ?
												<p className="color ftextview">
													<strong>{items.questions[0]?.choiceQuestion?.selectedChoices[0]?.value}</strong><br />
													<span dangerouslySetInnerHTML={{ __html: items?.questions[1]?.textQuestion?.answer }}></span>
												</p> : null :
											<p className="color ftextview">
												<span dangerouslySetInnerHTML={{ __html: items?.questions[0]?.textQuestion?.answer }}></span>
											</p>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className='myprofile_container'>
						<div className='myprofile_accordian'>
							<Accordion alwaysOpen>
								{profileQ?.profileQuestions?.map((profile, index) => (
									<Accordion.Item eventKey={index.toString()} key={profile?.id}>
										<Accordion.Header className='accordian_personal_detail'>{profile?.name}</Accordion.Header>
										<Accordion.Body className='profile_accordian_body'>
											{profile?.questions?.map((question) => (
												<div className='profileQ_container' key={question?.id}>
													<div className="profileQ_details">
														{question?.type === 'ChoiceQuestion' && (
															<div className='q_details'>
																<p className='q_heading'>{question?.name}</p>
																<p className='q_description'>
																	{question?.choiceQuestion?.selectedChoices?.map((ans, idx) => (
																		<span key={idx}>
																			{ans.value}
																			{idx !== question.choiceQuestion.selectedChoices.length - 1 && ','}{' '}
																		</span>
																	))}
																</p>
															</div>
														)}
														{question?.type === 'NumberQuestion' && (
															<div className='q_details'>
																<p className='q_heading'>{question?.name}</p>
																<p className='q_description'>{question?.numberQuestion?.answer}</p>
															</div>
														)}
														{question?.type === 'TextQuestion' && (
															<div className='q_details'>
																<p className='q_heading'>{question?.name}</p>
																<p className='q_description'>{question?.textQuestion?.answer}</p>
															</div>
														)}
														<button aria-label={t('myprofile.btnEdit', { title: question?.name})} onClick={() => onQuestionsOpen(question, profile?.id, profile?.description)} className='edit_status'>
															<FontAwesomeIcon icon={faPen} />
														</button>
													</div>
												</div>
											))}
										</Accordion.Body>
									</Accordion.Item>
								))}
								
								<Accordion.Item eventKey="gallery">
									<Accordion.Header onClick={() => sendCallOnlyAccordianOpen()}
										className='accordian_personal_detail'>
										 {t('member.gallery.photogalleries')}
									</Accordion.Header>
									<Accordion.Body className='profile_accordian_body'>
										{profileData != null && isGalleryResponse ? <div className='myprofile_loader'> <Spinner /></div> : <>
										<div>
											{gallery?.galleries?.length ? <p>{t('suggestions.clickongallery')}</p> : <p>{t('suggestions.noimageshared')}</p>}
										</div>
											<div className='s_gallery_wrapper'>
												{gallery?.galleries?.map((gallery, index) => (
													gallery.mediaDTOS ?
														<div key={gallery?.id}>
															<div
																onClick={() => openGallery(gallery)}
																className='s_gallery_container'>
																<img className='' src={gallery?.thumbnails?.[0] ? base64Image(gallery?.thumbnails?.[0].thumbnail) : UserIcon} alt={gallery?.name}/>
															</div>
															<div className='g_name_container'>
																<span className='galleryname'>{gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
																</span>
															</div>
														</div>
														:
														null))}
											</div>
										</> }
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					</div>
					{isPopupOpen ?
						<EditFreeText
							profileData={profileData}
							show={isPopupOpen}
							type={props.type}
							setShowFreeTextError={setShowFreeTextError}
							handleClose={closeFreeTextPopup}
							setRefreshQuestionaire={props.setRefreshQuestionaire}
						/> : null}
					{isStatusPopupOpen ?
						<EditStatus
							profileData={profileData}
							show={isStatusPopupOpen}
							type={props.type}
							handleClose={closeStatusPopup}
							setRefreshQuestionaire={props.setRefreshQuestionaire}
						/> : null}
					{isUploadImgeOpen ?
						<UploadImage
							profileData={profileData}
							show={isUploadImgeOpen}
							type={props.type}
							handleClose={closeUploadImagePopup}
							setAvatar={setAvatar}
							avatarUrl={avatar}
						/> : null}
					{isProfileQuestionOpen ?
						<ProfileQuestions
							profileData={storeProfileQ}
							show={isProfileQuestionOpen}
							type={props.type}
							handleClose={onQuestionsClose}
							setRefreshProfileData={setRefreshProfileData}
						/> : null}
					{isGalleryOpen ?
						<GalleryImages
							suggestionGalleryImages={myProfileGalleryImages}
							isGalleryLoading={isGalleryLoading}
							show={isGalleryOpen}
							close={closeImagePopup}
						/>
						: null
					}
				</div >
				:
				<Loader />
			}
		</>
	)
}

export default Myprofile;