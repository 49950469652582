import React, { useState, createRef, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../../context/loginContext';
import { Alert, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCropSimple,
    faExpand,
    faRotate,
    faRotateLeft,
    faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import heic2any from 'heic2any';
import { uploadAvatar } from '../../home/homeServices'

function ProfileImageCropper(props) {
    const { currentImage } = props;
    const [image, setImage] = useState();
    const [cropData, setCropData] = useState();
    const [isImageCropped, setIsImageCropped] = useState(false);
    const [isStatusPending, setIsStatusPending] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState(null)
    const { loginData: { accessToken } } = useContext(LoginContext);
    const cropperRef = createRef();
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    useEffect(() => {
        setImage(currentImage);
    }, [currentImage])
    const enableCropImage = (e) => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.zoomTo(0).crop();
            setIsImageCropped(true);
        }
    };
    const getNoCropData = (e) => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.zoomTo(0).clear();
            setIsImageCropped(false);
        }
    };
    const rotateLeft = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.rotate(-90);
            cropperRef.current?.cropper.zoomTo(0).setDragMode('none');
        }
    };
    const rotateRight = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.rotate(90);
            cropperRef.current?.cropper.zoomTo(0).setDragMode('none');
        }
    };
    const reset = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.reset();
            setIsImageCropped(false);
        }
    };
    const saveCroppedImage = async () => {
        if (cropperRef.current?.cropper) {
            setIsStatusPending(true);
            const newCropData = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
            const imageName = currentImage?.image?.name || 'default.jpg';
            const base = imageName.substring(0, imageName.lastIndexOf('.'));
            const ext = imageName.substring(imageName.lastIndexOf('.'));
            const timestamp = Date.now().toString();
            const lastThreeDigits = timestamp.slice(-3);
            const newImageName = `cropped${lastThreeDigits}${ext}`;
            const fileData = dataURLtoFile(newCropData, newImageName);
            let newImageUrl;
            if (fileData.type === 'image/heif' || fileData.type === 'image/heic') {
                const jpegBlob = await heic2any({
                    blob: fileData,
                    toType: 'image/jpeg',
                    quality: 1
                });
                newImageUrl = URL.createObjectURL(jpegBlob);
            } else {
                newImageUrl = URL.createObjectURL(fileData);
            }
            let formData = new FormData();
            formData.append('file', fileData, fileData.name);
            const category = props.uploadType.includes('partnership') ? "PARTNERSHIP" : "FRIENDSHIP";
            const data = {
                form: formData,
                category: category,
                language: currentLang,
                accessToken: accessToken
            };
            try {
                const result = await uploadAvatar(data);
                if (result.status === 200) {
                    props.setAvatar(newImageUrl)
                    props.setUploadType(null);
                    props.handleClose();
                    if(props.isAvatarHomePage != undefined) props.isAvatarHomePage(true);
                } else {
                    setIsErrorMessage(result.data.message);

                }
            } catch (error) {
                console.error("Upload error:", error);
                setIsErrorMessage("An error occurred while uploading the image.");

            }
            setIsStatusPending(false);
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };


    return (
        <div className='cropper_modal' size='lg' >
            <div>
                <div className='cropper_container'>
                    <Cropper
                        ref={cropperRef}
                        className='main_cropper'
                        initialAspectRatio={1}
                        preview=".img-preview"
                        // src={isImageCropped ? cropData : image}
                        src={image}
                        viewMode={2}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={true}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                        autoCrop ={false}
                    />
                </div>
                <div className='cropper_btns'>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='right_rotate'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-right_rotate`}>{t("cropper.rotateright")}</Tooltip>}
                        >
                            <Button
                                onClick={rotateRight}
                                className='mt-1'
                                variant="primary"
                                aria-label={t("cropper.rotateright")}
                            >
                                <FontAwesomeIcon icon={faRotateRight} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='left_rotate'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-left_rotate`}>{t("cropper.rotateleft")}</Tooltip>}
                        >
                            <Button
                                onClick={rotateLeft}
                                className='mt-1'
                                variant="primary"
                                aria-label={t("cropper.rotateleft")}
                            >
                                <FontAwesomeIcon icon={faRotateLeft} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='left_rotate'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-editImage`}>{t("cropper.crop")}</Tooltip>}
                        >
                            <Button
                                onClick={enableCropImage}
                                className='mt-1'
                                variant="primary"
                                aria-label={t("cropper.crop")}
                            >
                                <FontAwesomeIcon
                                    icon={faCropSimple}
                                />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='reset'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-not-crop`}>{`No ${t("cropper.crop")} image`}</Tooltip>}
                        >
                            <Button
                                onClick={getNoCropData}
                                className='mt-1'
                                variant="primary"
                                aria-label={`No ${t("cropper.crop")} image`}
                            >
                                <FontAwesomeIcon icon={faExpand} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group className='cropper_btn'>
                        <OverlayTrigger
                            key='reset'
                            placement='top'
                            overlay={<Tooltip id={`tooltip-reset`}>{t("cropper.reset")}</Tooltip>}
                        >
                            <Button
                                onClick={reset}
                                className='mt-1'
                                variant="primary"
                                aria-label={t("cropper.reset")}
                            >
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>
                        </OverlayTrigger>
                    </Form.Group>
                </div>
                {isErrorMessage !== null ?
                    (

                        <Alert
                            className='member_address mt-2'
                            variant="danger"
                            id='main_alert'
                            dismissible
                        >
                            <p>{isErrorMessage}</p>
                        </Alert>
                    ) : null}
                <div className='cropper_save_btn'>
                    <Form.Group className=''>
                        <Button
                            onClick={isStatusPending ? null : saveCroppedImage}
                            className='mt-1'
                            variant="primary"
                        >
                            {isStatusPending ?
                                <Spinner className='spinner_cropper' animation="border" variant="light" />
                                :
                                isImageCropped ? t("cropper.savecroppedImage") : t("cropper.saveImage")}
                        </Button>
                    </Form.Group>
                </div>
            </div>
        </div>
    );
}

export default ProfileImageCropper;
