
import { useEffect, useState, useContext } from "react";
import Spinner from '../../components/spinner';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { confirmEmail } from './mailConfirmationService'
import { LoginContext } from '../../context/loginContext';
import { Button } from "react-bootstrap";
import GlkHeader from '../../components/Header/GlkHeader';
import "./mailConfirmation.css";

function MailConfirmation() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    let {
        loginData: {
          accessToken
        }
      } = useContext(LoginContext);
    const [message, setMessage] = useState(null)

    useEffect(()=>{
        const token = location.search.split("=")[1]
        if(token) {
            const payload = { token: token}
            confirmEmail(payload).then((response) => {
                if (response.status === 200) {
                    if (accessToken) {
                        goHome()
                    } else {
                        navigate('/')
                    }
                } else {
                    setMessage(`${t('mailVerification.error')} : ${response.data.message}`)
                }
            })
        } else {
            setMessage(t('mailVerification.error'))
        }
    }, [])

    const goHome = () => {
        navigate('/home')
    }

    return (
        <>
            <div className='login_container mail_confirmation'>
                <GlkHeader />
                <div className="main_wrapper">
                    <div className="cancellation_box">
                        <div className="login_main_container">
                            {message ?
                                <div>
                                    {message}
                                    <div><Button onClick={goHome}>Home</Button></div>
                                </div>
                                : <Spinner />}
                        </div>
                    </div>
                    <div className="legal">
                        <a className="legal_link"
                            target="_blank"
                            href="https://www.gleichklang.de/impressum/"
                            aria-label="{t('member.footer.imprint')}">{t('member.footer.imprint')}</a> |
                        <a className="legal_link"
                            target="_blank"
                            href="https://www.gleichklang.de/datenschutz/"
                            aria-label="{t('member.login.dataProtection')}">{t('member.footer.dataProtection')}</a> |
                        <a className="legal_link"
                            target="_blank"
                            href="https://www.gleichklang.de/agb/"
                            aria-label="{t('member.footer.terms')}">{t('member.footer.terms')}</a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MailConfirmation;

