import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import {Button, Form, Modal, ModalBody, Alert, Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Friendship from '../../assets/images/icon_category_friendship-pd.png';
import Partnership from '../../assets/images/icon_category_partnership-pd.png';
import sharedToSuggestionIcon from '../../assets/images/sharedToSuggestionIcon.png';
import { shareGallery, getSuggestionToshareGallery } from './personalDetailsService';
import { LoginContext } from '../../context/loginContext';

function ShareGalleryView(props) {    
    const [allPartnership, setAllPartnership] = useState(false);
    const [allFriendship, setAllFriendship] = useState(false);
    const [partnershipMarkedInteresting, setPartnershipMarkedInteresting] = useState(false);
    const [friendshipMarkedInteresting, setFriendshipMarkedInteresting] = useState(false);
    const [customSelection, setCustomSelection] = useState(false);
    const [isShareGallery, setIsShareGallery] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isError , setIsError] = useState(false);
    const [suggestionToShareGallery, setSuggestionToShareGallery] = useState([]);
    const [excludeSuggestions, setExcludeSuggestions] = useState([]);
    const [includeSuggestions, setIncludeSuggestions] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [pageNo, setPageNo] = useState(1);
    const [isSuggestionToShareGallery, setIsSuggestionToShareGallery] = useState(true)
    const [loading, setLoading] = useState(false);
    const [galleryShareOptions, setGalleryShareOptions] = useState({})

    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);

    const getTitle = () => {
        switch (props.type) {
            case 'rename':
                return t('common.rename');
            case 'share':
                return t('common.share');
            case 'delete':
                return t('common.delete');
            default:
                return '';
        }
    }

    useEffect(() => {
        setAllPartnership(props?.currentGalleryData?.allPartnership || false);
        setAllFriendship(props?.currentGalleryData?.allFriendship || false);
        setPartnershipMarkedInteresting(props?.currentGalleryData?.partnershipMarkedInteresting || false);
        setFriendshipMarkedInteresting(props?.currentGalleryData?.friendshipMarkedInteresting || false);
        setCustomSelection(props?.currentGalleryData?.customSelection || false);
    }, [props]);    

    const resetSuggestions = () => {
        setPageNo(1);
        setIncludeSuggestions([]);
        setExcludeSuggestions([]);
        setSuggestionToShareGallery([]);
        setSearchValue('');
        setIsSuggestionToShareGallery(true);
    }

    useEffect(() => {
        resetSuggestions();
    }, [props?.currentGalleryData])

    useEffect(() => {
        if (customSelection && props?.currentGalleryData?.id) {            
            fetchSuggestions();
        }
    }, [pageNo, props?.currentGalleryData?.id]);

    useEffect(() => {
        if (customSelection && props?.currentGalleryData?.id) {
            resetSuggestions();
            fetchSuggestions();
        }
    }, [customSelection]);

    useEffect(() => {
        if (searchValue) {
            const searchInDelay = setTimeout(() => {
                setPageNo(1);
                setSuggestionToShareGallery([]);
                fetchSuggestions();
            }, 1500)
            return () => { clearTimeout(searchInDelay) }
        } else {
            setPageNo(1)
            setSuggestionToShareGallery([])
            if (props?.currentGalleryData?.id) {
                fetchSuggestions();
            }
        }
    }, [searchValue])

    const searchSuggestion = (value) => {
        setSearchValue(value);
    }

    const fetchSuggestions = () => {
        setLoading(true);
        const body = {
            accessToken: accessToken,
            language: i18n.language,
            search: searchValue,
            pageNo: pageNo,
            pageSize: 20,
            galleryId: props.currentGalleryData?.id
        };

        getSuggestionToshareGallery(body).then((result) => {
            if (result?.status === 200) {
                if (result.data.members.length !== 0) {
                    setSuggestionToShareGallery((prevMembers) => [...prevMembers, ...result.data.members]);
                    const updatedIncludedSuggestionView = result.data.members
                        .filter(member => member.included)
                        .map(member => member.relationshipId);

                    const updatedExcludedSuggestionView = result.data.members
                        .filter(member => !member.included)
                        .map(member => member.relationshipId);

                    setIncludeSuggestions(updatedIncludedSuggestionView);
                    setExcludeSuggestions(updatedExcludedSuggestionView);
                } else {
                    setIsSuggestionToShareGallery(false);
                }
            } else {
                setIsError(true);
                setErrorMessage(result?.data?.message);
            }
            setLoading(false);
        });
    };

    const chatContainerRef = useRef(null);
    const lastContactElementRef = useCallback(
        (node) => {
            if (chatContainerRef.current) chatContainerRef.current.disconnect();
            chatContainerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && isSuggestionToShareGallery) {
                    setPageNo((prevMessagePage) => prevMessagePage + 1);
                }
            }, { threshold: 1.0 });
            if (node) chatContainerRef.current.observe(node);
        },
        [loading, isSuggestionToShareGallery]
    );

    const handleIncludedChange = (relationshipId, included) => {
        const updatedIncludedSuggestion = included
            ? [...includeSuggestions, relationshipId].filter((v, i, a) => a.indexOf(v) === i)
            : includeSuggestions.filter(id => id !== relationshipId);

        const updatedExcludedSuggestion = !included
            ? [...excludeSuggestions, relationshipId].filter((v, i, a) => a.indexOf(v) === i)
            : excludeSuggestions.filter(id => id !== relationshipId);

        setIncludeSuggestions(updatedIncludedSuggestion);
        setExcludeSuggestions(updatedExcludedSuggestion);
        setSuggestionToShareGallery(prevMember =>
            prevMember.map(member =>
                member.relationshipId === relationshipId
                    ? { ...member, included }
                    : member
            )
        );
        const data = {
            id: props.currentGalleryData.id,
            allPartnership: allPartnership,
            partnershipMarkedInteresting: partnershipMarkedInteresting,
            allFriendship: allFriendship,
            friendshipMarkedInteresting: friendshipMarkedInteresting,
            customSelection: customSelection,
            excludeRelationshipIds: updatedExcludedSuggestion,
            includeRelationshipIds: updatedIncludedSuggestion
        };
        const body = {
            accessToken: accessToken,
            language: i18n.language,
            body: data
        };
        shareGallery(body).then((result) => {
            if (result?.status === 200) {
                setIsShareGallery(true);
            }
            else {
                setIsError(true);
                setErrorMessage(result?.data?.message);
            }
        });
    };
 
    const handleCheckboxChange = (checkboxName) => {
        const newState = !eval(checkboxName);
        eval(`set${checkboxName.charAt(0).toUpperCase() + checkboxName.slice(1)}(${newState})`);

        const isCustomselection = checkboxName === 'customSelection' ? newState : customSelection;
        setCustomSelection(isCustomselection);
        const customSelect = checkboxName === 'customSelection' ? newState : customSelection
        const data = {
            id: props.currentGalleryData.id,
            allPartnership: checkboxName === 'allPartnership' ? newState : allPartnership,
            partnershipMarkedInteresting: checkboxName === 'partnershipMarkedInteresting' ? newState : partnershipMarkedInteresting,
            allFriendship: checkboxName === 'allFriendship' ? newState : allFriendship,
            friendshipMarkedInteresting: checkboxName === 'friendshipMarkedInteresting' ? newState : friendshipMarkedInteresting,
            customSelection: customSelect,
            excludeRelationshipIds: customSelect ? excludeSuggestions : [],
            includeRelationshipIds: customSelect ? includeSuggestions : []
        };
        setGalleryShareOptions(data)
        const body = {
            accessToken: accessToken,
            language: i18n.language,
            body: data
        };
        shareGallery(body).then((result) => {
            if (result?.status === 200) {
                setIsShareGallery(true);
            }
            else {
                setIsError(true);
                setErrorMessage(result?.data?.message);
            }
        });
    };

    const onClose = () => {
        props.setShow(false);
        resetSuggestions();
        props.setCurrentGallery();
        if (isShareGallery) props.updateGalleryShare(galleryShareOptions);
    }

    return (
        <Modal show={props.show} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    {isError ? <Alert
                        className='member_address'
                        variant="danger"
                        id='main_alert'
                        onClose={() => setIsError(false)}
                        dismissible
                    >
                        <p>{errorMessage}</p>
                    </Alert> : null}
                    <Form.Group className="mb-3" controlId="popupForm.ControlInput1">
                        {props.type === 'share' ? (
                                <fieldset>
                                    <legend>
                                        {t('member.gallery.share')} {props.currentGalleryData?.name} {t('member.gallery.with')}
                                    </legend> 
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="friendship"
                                    checked={allPartnership}
                                    label={
                                        <span className="sharetosug">
                                            <img src={Partnership} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.proposal')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('allPartnership')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="partnership"
                                    checked={allFriendship}
                                    label={
                                        <span className="sharetosug">
                                            <img src={Friendship} alt="Friendship" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.suggestions')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('allFriendship')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="allpartnership"
                                    checked={partnershipMarkedInteresting}
                                    label={
                                        <span className="sharetosug">
                                            <img src={sharedToSuggestionIcon} alt="allpartnership" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.allpartnership')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('partnershipMarkedInteresting')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="allfriends"
                                    checked={friendshipMarkedInteresting}
                                    label={
                                        <span className="sharetosug">
                                            <img src={sharedToSuggestionIcon} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.allfriends')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('friendshipMarkedInteresting')}
                                />
                                <Form.Check
                                    className="msg_pre_checkbox share_gallery_checkbox"
                                    type="checkbox"
                                    id="suggestions"
                                    checked={customSelection}
                                    label={
                                        <span className="sharetosug">
                                            <img src={sharedToSuggestionIcon} alt="icon" style={{ marginRight: '5px' }} />
                                            {t('member.gallery.alloptions')}
                                        </span>
                                    }
                                    onChange={() => handleCheckboxChange('customSelection')}
                                />
                                {customSelection && <>
                                    <Form.Group className="mt-3 mb-2" id='numberquestion'>
                                        <Form.Control
                                            placeholder='Search here...'
                                            type="text"
                                            value={searchValue}
                                            onChange={(e) => searchSuggestion(e.target.value)}
                                            aria-label="Search members"
                                            /></Form.Group>
                                    <div className='include_exclude_container'><p className='exlcude'>Excluded</p><p className='inlcude'>Included</p></div>
                                    <div
                                        className='customshare'>
                                        {!loading ? suggestionToShareGallery?.map((member, index) => (
                                            <div
                                                ref={index == suggestionToShareGallery.length - 1 ? lastContactElementRef : null}
                                                key={index}
                                                className='customshare_container'>
                                                <Form.Check
                                                    className="red_exclude msg_pre_checkbox share_gallery_checkbox"
                                                    type="radio"
                                                    id={`${member.relationshipId}-1`}
                                                    name={`customshareGroup-${member.relationshipId}`}
                                                    checked={!member.included}
                                                    onChange={() => handleIncludedChange(member.relationshipId, false)}
                                                    aria-checked={!member.included}
                                                    aria-label={`Exclude ${member.alias}`}
                                                />
                                                <Form.Check
                                                    className="green_include msg_pre_checkbox share_gallery_checkbox"
                                                    type="radio"
                                                    id={`${member.relationshipId}-2`}
                                                    name={`customshareGroup-${member.relationshipId}`}
                                                    checked={member.included}
                                                    onChange={() => handleIncludedChange(member.relationshipId, true)}
                                                    aria-checked={member.included}
                                                    aria-label={`Include ${member.alias}`}
                                                />
                                                <p>{member.alias}</p>
                                            </div>
                                        )) :
                                            <div className='custom_member_loader'>
                                                <Spinner />
                                            </div>
                                        }
                                    </div>
                                </>}
                                 </fieldset>
                        ) : (
                            <p>
                                {props.currentGalleryData?.name} {t('member.audio.deletePostFix')}
                            </p>
                        )}
                    </Form.Group>
                </Form>
            </ModalBody>
            <Modal.Footer>
                {props.type === 'delete' && (
                    <Button variant="outline-danger" onClick={() => { props.onGalleryDelete(props?.currentGalleryData, true) }}>
                        {t('common.delete')}
                    </Button>
                )}
                <Button variant="secondary" onClick={() => onClose()}>
                    {t('common.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ShareGalleryView;
