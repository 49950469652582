import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import './menubar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState, useEffect } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useNavigate } from 'react-router-dom';
import { getQuestionaireId } from '../personalDetails/personalDetailsService';

function Menubar({mobileMenuVisible, handleLogout}) {
    const [questionaireName, setQuestionaireName] = useState();

    const { t, i18n } = useTranslation();
    let {
        loginData: {
            memberDetails,
            accessToken
        },
    } = useContext(LoginContext);
    const navigate = useNavigate();

    const currentLang = i18n.language;

    const token_lang = {
      language: currentLang,
      accessToken
    }

    useEffect(() => {
        getQuestionaireId(token_lang).then((response) => {
          if (response?.status == 200) {
            const questionaireValue = response?.data?.questionnaireAndIds;
            setQuestionaireName(questionaireValue)
          }
        })
      }, [currentLang]);

    const menuList = [
        {
            variant: "Secondary",
            title: t('member.menubar.overview'),
            items: [],
            menuShow: true,
        },
        {
            variant: "Primary",
            title: t('member.menubar.memberShip'),
            menuShow: true,
            items: [
                t('member.menubar.personaldata'),
                t('member.menubar.subscription'),
                t('member.menubar.msgtoGk'),
                t('member.menubar.statistics'),
            ],
            link: [
                '/membership/personaldata',
                '/membership/subcription',
                '/membership/message-to-gleichklang',
                '/membership/statistics'
            ]
        },
        {
            variant: "Success",
            title: t('member.menubar.suggestions'),
            menuShow: true,
            link: [
                '/suggestions/suggestions',
                '/suggestions/lastseen',
                '/suggestions/community-contacts',
                '/suggestions/messenger'
            ],
            items: [t('member.menubar.suggestions'), t('member.menubar.lastseen'), t('member.menubar.communityContacts'), t('member.menubar.messages')],
        },
        {
            variant: "Info",
            title: t('member.menubar.personalDetail'),
            menuShow: true,
            link: [               
                '/personal-details/community-contacts',
                '/personal-details/social-environement',
                '/personal-details/appearance',
                '/personal-details/overall-personality',
                '/personal-details/hobbies',
                '/personal-details/social-engagement',
                '/personal-details/member-servey',
                '/personal-details/gallery',
                '/personal-details/audio',
            ],
            items: [
                questionaireName?.kontaktliste?.value,
                questionaireName?.person?.value,
                questionaireName?.aussehen?.value,
                questionaireName?.persoenlichkeit?.value,
                questionaireName?.hobby_v?.value,
                questionaireName?.gesellschaft?.value,
                questionaireName?.registration?.value,
                t('member.menubar.galaries'),
                t('member.menubar.audio')
            ],
        },
        {
            variant: "Warning",
            title: t('member.menubar.partnership'),
            menuShow: memberDetails?.partnershipActive,
            link: [
                '/partnership/myprofile',
                '/partnership/what-are-you-looking-for',
                '/partnership/your-thoughts-on-relationship',
                '/partnership/regional-search',
                '/partnership/about-you',
            ],            
            items: [
                t('member.menubar.myprofile'),   
                questionaireName?.partner?.value,                            
                questionaireName?.partnerschaft?.value,            
                questionaireName?.["partner.region_question"]?.value, 
                questionaireName?.ptext?.value,                    
            ],
        },
        {
            variant: "Danger",
            title: t('member.menubar.friendship'),
            menuShow: memberDetails?.friendshipActive,
            link: [
                '/friendship/myprofile',
                '/friendship/what-are-you-looking-for',
                '/friendship/your-thoughts-on-relationship',
                '/friendship/regional-search',
                '/friendship/about-you',
            ],
            items: [
                t('member.menubar.myprofile'),  
                questionaireName?.freund?.value,               
                questionaireName?.freundschaft?.value,               
                questionaireName?.["friend.region_question"]?.value,    
                questionaireName?.ftext?.value                     
            ]
        },
    ];

    const goToMembershipData = (url) => {
        navigate(url)
    } 

    return (
        <nav className={mobileMenuVisible ? "menubar_container mobile_nav_show" : "menubar_container"}>           
            {menuList.map((menu) =>
                menu.items.length > 0 ? (
                    menu.menuShow == true ?
                        <DropdownButton
                            className={menu.variant}
                            as={ButtonGroup}
                            key={menu.variant}
                            id={`dropdown-variants-${menu.variant}`}
                            variant={menu.variant.toLowerCase()}
                            title={menu.title}
                        >
                            {menu.items.map((item, index) => (
                                <Dropdown.Item onClick={() => goToMembershipData(menu?.link?.[index])} key={index} eventKey={index + 1}>
                                   {item}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton> :
                        null
                ) : (
									<>
                    <Button 
                    onClick={() => goToMembershipData('/home')}
                    className='overview_btn' 
                    variant={menu.variant.toLowerCase()} 
                    key={menu.variant}>
                        {menu.title}
                    </Button>
										<button
										onClick={(e) => {handleLogout(e)}}
										className='logout_btn' 
                                        aria-label="Logout">
											<FontAwesomeIcon icon={faSignOutAlt} />
										</button>	
									</>
                )
            )}
        </nav>
    );
}

export default Menubar;