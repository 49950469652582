import React from "react";
import './PageNotFound.css'

function PageNotFound() {
  return (
    <div className="pageNotFound_container">
      <div className="container_404">
        <span className="word" >404</span>
        <span className="word">Sorry!</span>
        <span className="word">Page Not Found</span>      
      </div>
      <div className="scientist">
        <div className="face">
          <div className="neck"></div>
          <div className="hair hair-l"></div>
          <div className="hair hair-r"></div>
          <div className="ear ear-l"></div>
          <div className="ear ear-r"></div>
          <div className="mouth"></div>
          <div className="moustache"></div>
          <div className="nose"></div>
          <div className="brow brow-l"></div>
          <div className="brow brow-r"></div>
          <div className="eye eye-l"></div>
          <div className="eye eye-r"></div>
          <div className="specs specs-l"></div>
          <div className="specs specs-r"></div>
          <div className="specs-middle"></div>
        </div>
        <div className="dress">
          <div className="arm arm-l"></div>
          <div className="arm arm-r"></div>
          <div className="coat coat-l"></div>
          <div className="coat coat-r"></div>
        </div>
        <div className="desk">
          <div className="beaker">
            <div className="bubbles bubble-1"></div>
            <div className="bubbles bubble-2"></div>
            <div className="bubbles bubble-3"></div>
            <div className="chemical"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
