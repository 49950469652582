import React from 'react'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import { numberOnlyWithDigit } from '../../commonMethods';

function NumberQuestions(props) {
    const {
        data,
        questionReference,
        errResponse,
        answers,
        setAnswers,
        onAlertOpen,
        qgroup,
        handleOnChange,
        handleOptionClick,
        updateErrorState,
        clearErrorState,
        errorMgsByID,
        validateMultipleRanges
    } = props;

    const { t, i18n } = useTranslation();

    const handleTapNumberQuestion = (e, data, GqId) => {
        if (data.required) {
            if (e.target.value === "") {
                updateErrorState((t('registration.questionnaires.fieldRequired')), data.id)
            } else {
                if (e.target.min && e.target.max) {
                    validateMultipleRanges(e, data.id, GqId)
                } else {
                    clearErrorState(data.id);
                }
            }
        } else {
            if (e.target.min && e.target.max) {
                validateMultipleRanges(e, data.id, GqId)
            }
        }
    }

    const handleTapBlock = (e, data, GqId) => {
        if (e.type === "keydown" && e.keyCode === 9 && e.shiftKey !== true) {
            if (data.required) {
                if (e.target.value === "") {
                    updateErrorState((t('registration.questionnaires.fieldRequired')), data.id)
                    e.preventDefault()
                } else {
                    if (e.target.min && e.target.max) {
                        if (validateMultipleRanges(e, data.id, GqId)) e.preventDefault()
                    } else {
                        clearErrorState(data.id);
                    }
                }
            } else {
                if (e.target.min && e.target.max) {
                    if (validateMultipleRanges(e, data.id, GqId)) e.preventDefault()
                }
            }
        }
    }


    let numberQuestions = <div key={`number-${data.id}`} className='questionair_alignment'
        ref={(e) => questionReference(data, e)}
    >
        {data.enabledQuestion === true ? <div>
            <Form.Group className="mt-3 dropdown">
                <div className="disriptionI_label" id={`description-${data.id}`}>
                    {data?.description ?
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            onClick={() => onAlertOpen(data?.description)}
                        /> : null}
                    <Form.Label id={`label-${data.id}`} className="radio_label" htmlFor={data.id}>
                        {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                    </Form.Label>
                </div>
                <Form.Control
                    defaultValue={answers[data.id]?.answerValues[0] || ''}
                    type="text"
                    id={data.id}
                    data-qid={(qgroup.questions.length == 2 || qgroup.questions.length == 3) ? qgroup.id : data.id}
                    aria-label={errResponse?.errorMessageByQuestionId?.[data.id] && errorMgsByID}
                    className={errResponse?.errorMessageByQuestionId?.[data.id] && 'is-invalid'}
                    min={data.numberQuestion.minValue}
                    max={data.numberQuestion.maxValue}
                    onChange={(e) => {
                        handleOptionClick(data, e.target.value, qgroup)
                        handleOnChange()
                    }}
                    onKeyUp={(e) => numberOnlyWithDigit(e, 3)}
                    onKeyDown={(e) => handleTapBlock(e, data, qgroup.id)}
                    onBlur={(e) => handleTapNumberQuestion(e, data, qgroup.id)}
                    aria-required={data.required ? 'true' : 'false'}
                />

                {data.askVisibleToOthers === true ?
                    <Form.Check id={`profileVisible${data.id}`}
                        name={`profileVisible${data.id}`}
                        type="checkbox"
                        label={t('common.visibleToOthers')}
                        className='msg_pre_checkbox'
                        onClick={(e) => {
                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                answers[data.id].profileVisible = e.target.checked
                                setAnswers(answers)
                            }
                        }}
                    >
                    </Form.Check> : null}
            </Form.Group>
            {errResponse?.errorMessageByQuestionId?.[data.id] && (
                <Alert className='alert_questionaire'
                    variant="danger" id={`err_${data.id}`}>{errResponse?.errorMessageByQuestionId?.[data.id]}</Alert>
            )}
        </div> : null}
    </div>

    return (
        <div>{numberQuestions}</div>
    )
}

export default NumberQuestions;