import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import { LoginContext } from '../../context/loginContext';

function ImagesGalleryPopup(props) {

    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);

    const getTitle = () => {
        switch (props.type) {
            case 'rename':
                return t('common.rename');
            case 'share':
                return t('common.share');
            case 'delete':
                return t('common.delete');
            default:
                return '';
        }
    }


    const onClose = () => {
        props.setShow(false);
    }

    return (
        <Modal show={props.show} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Form>
                    <Form.Group className="mb-3" controlId="popupForm.ControlInput1"> 
                        <p>
                            {props?.currentGalleryData?.mediaDTOS.find(image => image.id === props.imageId)?.name || props?.currentImageName} 
                            {t('member.audio.deletePostFix')}
                        </p> 
                                                              
                    </Form.Group>
                </Form>
            </ModalBody>
            <Modal.Footer>
                {props.type === 'delete' && (
                    <Button variant="outline-danger"
                        onClick={() => { props.moveOrCopyOrDeleteImage(props.imageId, props.type) }}
                    >
                        {t('common.delete')}
                    </Button>
                )}
                <Button variant="secondary" onClick={() => onClose()}>
                    {t('common.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

 export default ImagesGalleryPopup;
