import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import GlkHeader from '../../components/Header/GlkHeader';
import Questionaire from './Questionnaire/Questionnaire.js';
import { getQuestionaires, getCurrentURL, gotoNextQuestionaire } from './registrationProcessService';
import { LoginContext } from '../../context/loginContext';
import PartnershipPost from './partnershipPost';
import MemberPreconfigNotification from './memberPreconfigNotification';
import questionnairesImg from "../../assets/images/questionnaires.png";
import regionalSearchImg from "../../assets/images/regional_search.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PaymentResult from './paymentResult';
import PersonalPost from './personalPost';
import FriendshipPost from './friendshipPost';
import SubscriptionOffer from './subscriptionOffer';
import Laststep from './laststep';
import MemberAddress from './memberAddress';
import Payment from './payment';
import { getMemberDetails } from '../../pages/login/loginService.js';
import {  useNavigate } from "react-router-dom";
import NoComponent from './noComponent.js';
import Spinner from '../../components/spinner.js';
import usePreventBack from './preventBack/preventBack'
import { getQuestionaireId } from '../personalDetails/personalDetailsService.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Toaster from '../Toaster/Toaster';


function Registration() {
  const [storeQuestionaire, setStoreQuestionaire] = useState(null);
  const [questionaireId, setQuestionaireId] = useState();
  const [spinner, setSpinner] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState()
  const [allQuestionairIds, setAllQuestionairIds] = useState();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  let navigate = useNavigate();

  let {
    loginData: {
      accessToken,
      currentUrl
    },
    dispatch
  } = useContext(LoginContext);

  usePreventBack();

  useEffect(() => {
    if (!currentUrl) {
      getCurrentURL(accessToken).then((res) => {
        dispatch({ type: 'CURRENT_URL', data: res.data });
        if (res.data?.nextQuestionnaire !== null) {
          setQuestionaireId(currentUrl?.nextQuestionnaire)
        } else {
          setQuestionaireId(currentUrl?.nextStep)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (currentUrl?.nextQuestionnaire !== null) {
      setQuestionaireId(currentUrl?.nextQuestionnaire)
    }
    else {
      setQuestionaireId(currentUrl?.nextStep)
    }
  }, [currentUrl])

  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    const fetchQuestionnaires = async () => {
        setSpinner(true)
      if (typeof questionaireId === 'number') {
        const res = await getQuestionaires(token_lang, questionaireId);
        if (res?.status === 200) {
          setStoreQuestionaire(res.data);
          setSpinner(false)
        } else {
          setSpinner(false)
        }
      }
      else setSpinner(false)
    };
    fetchQuestionnaires();
  }, [currentLang, questionaireId])

  const handleNextQustionaire = () => {
    setSpinner(true)
    gotoNextQuestionaire(accessToken).then((res) => {
      if (res?.status === 200) {
        dispatch({ type: 'CURRENT_URL', data: res.data });
        if (typeof res.data?.nextQuestionnaire === 'number') {
          setQuestionaireId(res.data?.nextQuestionnaire)
          setSpinner(false);
        }
        else if (res.data?.registrationComplete) {
          getMemberDetails(accessToken).then((response) => {
            dispatch({ type: 'MEMBER_DETAILS', data: response.data });
            if(response.data.subscriptionState && response.data.subscriptionState !== null
              && response.data.subscriptionState === 'ACTIVE') {
              navigate('/home', { replace: true });
            } else if(response.data.pendingPrepayment === true || response.data.pendingDirectDebitPayment === true) {
              navigate('/payment', { replace: true });
            }            
             else {
              navigate('/inactive/subscription', { replace: true })
            }
            setSpinner(false);
          })
        }
        else if (res.data?.nextStep !== null) {
          dispatch({ type: 'CURRENT_URL', data: res.data });
          setQuestionaireId(res.data?.nextStep)
          setSpinner(false);
        }
      }else{
        setSpinner(false);
      }
    }).catch((err) => {
      setSpinner(false)
    })
  }

  useEffect(() => {
    getQuestionaireId(token_lang).then((response) => {
      if (response?.status == 200) {
        const questionaireId = response?.data?.questionnaireAndIds;
        setAllQuestionairIds(questionaireId)
      }
    })
  }, [currentLang])

  const toastRef = useRef();
  const toastMessage = t("registration.payment.payment_successful")
	const handleShowToast = () => {
		const toastElement = toastRef.current;
		const options = {
			animation: true,
			autohide: true,
			delay: 3000,
		};
		if (toastElement) {
			const bootstrapToast = new bootstrap.Toast(toastElement, options);
			bootstrapToast.show();
		}
	}; 

  let componentToRender;
  let questionnaireClass;
  let questionairImage = questionnairesImg;
  
  switch (questionaireId?.toString()) {
    case allQuestionairIds?.partner?.key:  // 1
    case allQuestionairIds?.freund?.key:  // 2
    case allQuestionairIds?.ptext?.key:  // 3
    case allQuestionairIds?.ftext?.key:  // 4
    case allQuestionairIds?.aussehen?.key:  // 5
    case allQuestionairIds?.person?.key:  // 6
    case allQuestionairIds?.hobby_v?.key:  // 7
    case allQuestionairIds?.partnerschaft?.key:  // 8
    case allQuestionairIds?.gesellschaft?.key:  // 9
    case allQuestionairIds?.persoenlichkeit?.key:  // 10
    case allQuestionairIds?.freundschaft?.key:  // 11
    case allQuestionairIds?.['partner.region_question']?.key:  // 12
      questionairImage = regionalSearchImg;  
    case allQuestionairIds?.['friend.region_question']?.key:  // 13
      questionairImage = regionalSearchImg;
    case allQuestionairIds?.registration?.key:  // 14
    case allQuestionairIds?.kontaktliste?.key:  // 25
      questionnaireClass = "step_two";
      componentToRender = <Questionaire
        questionaire={storeQuestionaire}
        setStoreQuestionaire={setStoreQuestionaire}
        setQuestionaireId={setQuestionaireId}
        allQuestionairIds={allQuestionairIds}
      />;
      break;
    case 'PARTNERSHIPPOST':
      questionnaireClass = "step_two";
      componentToRender = <PartnershipPost
        handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'CONFIGNOTIFICATION':
      questionnaireClass = "step_four";
      componentToRender = <MemberPreconfigNotification
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'PAYMENTRESULT':
      questionnaireClass = "step_three";
      componentToRender = <PaymentResult
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      setQuestionaireId={setQuestionaireId}
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'PERSONALPOST':
      questionnaireClass = "step_two";
      componentToRender = <PersonalPost
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'FRIENDSHIPPOST':
      questionnaireClass = "step_two";
      componentToRender = <FriendshipPost
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'SUBSCRIPTIONOFFER':
      questionnaireClass = "step_three";
      componentToRender = <SubscriptionOffer
      setPaymentMethod={setPaymentMethod}
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'LASTSTEP':
      questionnaireClass = "step_four";
      componentToRender = <Laststep
      handleNextQustionaire={handleNextQustionaire}
      />;
      break;
    case 'MEMBERADDRESS':
      questionnaireClass = "step_two";
      componentToRender = <MemberAddress
      setQuestionaireId={setQuestionaireId}
      />;
      break;
    case 'PAYMENT':
      questionnaireClass = "step_three";
      componentToRender = <Payment
      setPaymentMethod={setPaymentMethod}
      setQuestionaireId={setQuestionaireId}
      handleShowToast={handleShowToast}
      />;
      break;
    default:
      componentToRender =  <NoComponent />
  }

  
  switch (questionaireId) {
    case 3:
    case 4:
    case 14:
      questionnaireClass = "step_four";
      break;
  }

 

  return (
    <div>
      <GlkHeader />      
      <main className={'main_wrapper preregistration_wrapper questionnaire_wrapper '+questionnaireClass}>
        <div className="banner_wrapper">
          <div className="banner_container">
            <div className='banner_content'>
              <div className="banner_description">
                <h1 className='registration_label'>{t('member.registration.questionnaires')}</h1>
                <p><strong>{t('member.registration.h2label')}</strong></p>
                <p>{t('member.registration.plabel')}</p>
              </div>
            </div>
            <div className="banner_image">
              <img src={questionairImage} alt={t('member.registration.questionnaires')} />
            </div>
          </div>
        </div>
        <div className="questionaire_in_menubar">
          <div className="container-fluid registration_steps">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="step_box one">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 1"><FontAwesomeIcon icon={faCheck} /></span>
                    </div>
                    <h2>{t('member.preregistration.registerHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.registerSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box two">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 2"><span>2</span><FontAwesomeIcon icon={faCheck} /></span>
                    </div>
                    <h2>{t('member.preregistration.profileHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.profileSubheading')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_box three">
                  <div className="step_headline">
                    <div className="step_count">
                      <span aria-label="Information 3"><span>3</span><FontAwesomeIcon icon={faCheck} /></span>
                    </div>
                    <h2>{t('member.preregistration.finalizeHeading')}</h2>
                  </div>
                  <p>
                    {t('member.preregistration.finalizeSubheading')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {spinner ? <Spinner/> : componentToRender}
        </div>
      </main>
      <Toaster
        toastMessage={toastMessage}
        toastRef={toastRef}
      />
    </div>
  )
}

export default Registration;